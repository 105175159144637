import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import gql from 'graphql-tag';

// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';

import {
  DeleteOrgMutation,
  ReadOrgQuery,
  ReadAllOrgsQuery,
  UpdateOrgInput,
  UpsertOrgMutation,
} from '../types/Org';

export const getAllOrganizationsQuery = async (): Promise<GraphQLResult<ReadAllOrgsQuery>> => {
  const res = await API.graphql(graphqlOperation(gql`
    query readAllOrgs {
      readAllOrgs {
        orgId,
        orgName,
        calendarProvider
      }
    }
  `)) as GraphQLResult<ReadAllOrgsQuery>;

  return res;
};

const createOrgMutation = gql`
  mutation ($input: CreateOrgInput!) {
    createOrg(input: $input) {
      orgId,
    }
  }
`;

const updateOrgMutation = gql`
  mutation ($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      orgId,
    },
  }
`;

export const updateOrganization = async (
  organizationInput: UpdateOrgInput,
  createNewOne: boolean,
): Promise<GraphQLResult<UpsertOrgMutation>> => {
  let mutation = updateOrgMutation;
  if (createNewOne) {
    mutation = createOrgMutation;
  }
  const res = await API.graphql(graphqlOperation(mutation, {
    input: {
      ...organizationInput,
      feedbackConfig: JSON.stringify(organizationInput.feedbackConfig),
      liveConfig: JSON.stringify(organizationInput.liveConfig),
      smartmapSDKConfig: JSON.stringify(organizationInput.smartmapSDKConfig),
      azureClientCredentials: JSON.stringify(organizationInput.azureClientCredentials),
      extras: JSON.stringify(organizationInput.extras),
      externalOrgMergeConfigs: JSON.stringify(organizationInput.externalOrgMergeConfigs),
    },
  })) as GraphQLResult<UpsertOrgMutation>;

  return res;
};

export const readOrgData = async (
  orgId: string,
): Promise<GraphQLResult<ReadOrgQuery>> => {
  const res = await API.graphql(graphqlOperation(gql`
    query readOrg {
      readOrg(orgId: "${orgId}") {
        calendarProvider,
        googleToken,
        azureToken,
        calendarBackendUrl,
        orgId,
        orgName,
        hasBitwards,
        feedbackEmail,
        feedbackConfig,
        liveConfig,
        smartmapSDKConfig,
        typeformURL,

        editorKeyRead,
        editorKeyReadWriteMerge,
        importKeyReadWriteMerge,
        metaLiveKeyRead,
        metaLiveKeyReadWriteMerge,
        liveKeyRead,
        liveKeyReadWriteMerge,
        publishedKeyRead,
        publishedKeyReadWriteMerge,
        smartmapAPIKey,
        azureClientCredentials,
        hasBitwardsOAuth,
        ghostBustingEnabled,
        carParkingEnabled,
        extras,
        externalOrgMergeConfigs,
        mixpanelToken,
        sensorSpeedUp,
        idpFederationProvider,
        idpFederationTenantName,
      }
    }
  `)) as GraphQLResult<ReadOrgQuery>;
  return res;
};

export const deleteOrg = async (
  orgId: string,
): Promise<GraphQLResult<DeleteOrgMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation deleteOrg {
      deleteOrg(orgId: "${orgId}") {
        orgId
      }
    }
  `)) as GraphQLResult<DeleteOrgMutation>;
  return res;
};
