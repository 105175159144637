import React, {
  useEffect,
  useRef,
} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button, Paper } from '@material-ui/core';

import history from '../history';

import TableComponent, { TableTitles } from '../UserManagement/TableComponent';
import './styles.scss';
import {
  SnackbarMessageElement,
  TableComponentElement,
} from '../../configs/types/Common';
import { Org } from '../../configs/types/Org';
import { User } from '../../configs/types/User';
import { EmailPattern } from '../../configs/types/EmailPattern';
import { filterUsers } from '../UserManagement/utils';
import {
  getAllOrganizationsQuery,
} from '../../configs/graphql/org';
import SnackbarMessage from '../Dialog/SnackbarMessage';

const OrganizationManagement: React.FC = () => {
  const [
    organizations,
    setOrganizations,
  ] = React.useState<Org[]>([]);
  const [
    filteredOrganizations,
    setFilteredOrganizations,
  ] = React.useState<Org[]>([]);
  const organizationTableRef = useRef<TableComponentElement>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarButton, setSnackbarButton] = React.useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();

  const getOrganizations = async () => {
    const { data, errors } = await getAllOrganizationsQuery();
    if (data && data.readAllOrgs) {
      setOrganizations(data.readAllOrgs);
      setFilteredOrganizations(data.readAllOrgs);
    }

    if (errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach((error: Error) => {
        errorMessages.push(error.message);
      });
      setSnackbarMessage(`Some of the organizations were missing properties ${errorMessages}`);
      setSnackbarButton('Retry?');
      snackbarRef?.current?.handleOpen();
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const filterContent = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (event.target.value) {
      let result: Array<EmailPattern | User | Org> = [];
      if (type === 'organizations') {
        result = filterUsers(event.target.value, organizations, 'orgId');
        setFilteredOrganizations(result as Org[]);
        organizationTableRef?.current?.handleSetPage();
      }
    } else if (type === 'organizations') {
      setFilteredOrganizations(organizations as Org[]);
    }
  };

  const onSnackbarButtonClick = () => {
    getOrganizations();
  };
  const organizationTableType = 'organizations';
  const organizationTableTitles: TableTitles[] = [
    {
      title: 'Id',
      id: 'orgId',
      numeric: false,
    },
    {
      title: 'Name',
      id: 'orgName',
      numeric: false,
    },
    {
      title: 'Calendar Provider',
      id: 'calendarProvider',
      numeric: false,
    },
    {
      title: 'Edit',
      id: 'edit',
      numeric: false,
    },
  ];
  const organizationTableColumns: string[] = ['orgId', 'orgName', 'calendarProvider'];

  return (
    <div className="organization-management">
      <Paper className="organization-management-paper">
        <div>
          <Typography variant="h4">Organization Management</Typography>

          <div className="organization-management-sub-title">
            <div className="organization-management-sub-title-approved-emails">
              <Typography variant="h5">Organizations</Typography>
              <Button
                className="organization-management-sub-title-approved-emails-button"
                onClick={() => { history.push('./add-new-organization'); }}
                variant="contained"
                color="primary"
              >
                Add New
              </Button>

            </div>
            <TextField
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => {
                filterContent(
                  ev, organizationTableType,
                );
              }}
              label="Search"
            />
          </div>
          <TableComponent
            ref={organizationTableRef}
            type={organizationTableType}
            tableTitles={organizationTableTitles}
            columns={organizationTableColumns}
            data={filteredOrganizations}
          />

          <SnackbarMessage
            message={snackbarMessage}
            ref={snackbarRef}
            snackbarButton={snackbarButton}
            onButtonClick={onSnackbarButtonClick}
          />
        </div>
      </Paper>
    </div>
  );
};

export default OrganizationManagement;
