import React, {
  CSSProperties,
  ReactNode,
  FC,
} from 'react';

const style: { loginView: CSSProperties } = {
  loginView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '64px',
  },
};

type ProfileViewProps = {
  children: ReactNode,
};

const ProfileView: FC<ProfileViewProps> = ({ children }: ProfileViewProps) => (
  <div style={style.loginView}>
    {children}
  </div>
);

export default ProfileView;
