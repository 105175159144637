import { MIGRATION_ENDPOINT } from '../../config';

const migrate = async (oldToken: string, newToken: string): Promise<unknown> => {
  const params: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: newToken,
    },
    body: oldToken,
  };

  const response = await fetch(MIGRATION_ENDPOINT, params);

  if (response.ok) {
    return response.json();
  }

  throw new Error(String(response.status));
};

export default migrate;
