import {
  FC,
  useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import MigrateView from './MigrateView';
import APIKeyForm from './APIKeyForm';
import migrate from './migrationAPI';

const style = {
  migrate: {
    width: '90%',
    maxWidth: '960px',
    padding: '24px',
    marginBottom: '48px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px',
    mrginBottom: '16px',
  },
};
/*
  Get new style editor API key from local storage
*/
const getV2APIKey = () => {
  const apiKeys = JSON.parse(localStorage.getItem('API_KEYS') || '{}');
  const editorAPIKey = apiKeys.EDITOR_API_KEY;
  return editorAPIKey;
};

const Migrate: FC = () => {
  const [token, setToken] = useState('');
  const [decodedToken, setDecodedToken] = useState<{ sub: string } | null>(null);

  const handleTokenChange = (
    tokenValue: string | null,
    decodedTokenValue: { sub: string } | null,
  ) => {
    if (tokenValue && decodedTokenValue) {
      setToken(tokenValue);
      setDecodedToken(decodedTokenValue);
    } else {
      setToken('');
      setDecodedToken(null);
    }
  };

  const handleMigration = async () => {
    const newToken = getV2APIKey();
    // Get temp API key

    try {
      if (newToken) {
        await migrate(token, newToken);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MigrateView>
      <Card style={style.migrate}>
        <Typography
          variant="h4"
          gutterBottom
        >
          Migrate
        </Typography>
        <Typography paragraph>
          This tool allows you to migrate old customer&apos;s data into this account.
          <br />
        </Typography>
        <Typography paragraph>
          Start by pasting old customer&apos;s write API key below.
        </Typography>
        <APIKeyForm
          tokenChanged={handleTokenChange}
        />

        {decodedToken
          && (
            <Typography>
              Click migrate to start using
              {' '}
              <strong>{decodedToken.sub}</strong>
              &apos;s data with this account.
            </Typography>
          )}
        {decodedToken
          && (
            <div style={style.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMigration}
              >
                Migrate
              </Button>
            </div>
          )}
      </Card>
    </MigrateView>
  );
};

export default Migrate;
