export const defaultExternalOrgMergeConfigs = [{
  title: 'external shared office spaces',
  apiKey: 'PUBLISH_READ_API_KEY',
  metaLiveKeyRead: 'META_LIVE_READ_API_KEY',
  buildingMergeMappings: [],
},
{
  title: 'more spaces to our existing building',
  apiKey: 'PUBLISH_READ_API_KEY',
  metaLiveKeyRead: 'META_LIVE_READ_API_KEY',
  buildingMergeMappings: [{
    ours: 'OUR_BUILDING_REF_FOR_BUILDING_1',
    theirs: 'THEIR_BUILDING_REF_FOR_BUILDING_1',
  }],
},
];
