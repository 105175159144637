import {
  FC,
  useState,
  useEffect,
} from 'react';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Auth } from 'aws-amplify';
import { cognito } from './Cognito';

import LoginView from './LoginView';

import {
  saveAPIKeys,
  useAuth,
} from './utils';

const style = {
  loginButton: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  agreeTerms: {
    marginTop: '16px',
  },
  errorMessage: {
    color: 'red',
  },
};

interface SignUpProps {
  type?: string;
}

const SignUp: FC<SignUpProps> = (props: SignUpProps) => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCreatingAccount, setShowCreatingAccount] = useState(false);
  const [showSignUpSpinner, setShowSignUpSpinner] = useState(false);

  const { setAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (
      props.type === 'organization'
      || window.location.search.includes('type=organization')
    ) {
      cognito.switchToOfficeAppPool();
    } else {
      cognito.switchToDashboardAppPool();
    }
  }, [props.type]);

  const validateFormFields = () => {
    if (!email) throw new Error('Invalid Email');
    if (!firstName) throw new Error('Invalid First Name');
    if (!lastName) throw new Error('Invalid Last Name');
    if (!password) throw new Error('Invalid Password');
    if (password.length < 8) throw new Error('Password must be at least 8 characters long.');

    if (password !== confirmPassword) throw new Error('Passwords do not match');
  };

  const handleSignUp = async () => {
    setErrorMessage('');
    setShowSignUpSpinner(true);
    // params.location.search.includes("?code=")
    try {
      validateFormFields();
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err.message);
      setShowSignUpSpinner(false);
      return;
    }

    const cognitoAttributes = {
      given_name: firstName,
      family_name: lastName,
      email,
    };
    try {
      const response = await cognito.signUp(email, password, cognitoAttributes);
      if (response) {
        setShowConfirmationForm(true);
        setShowLoginForm(false);
      }
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err.message);
    }

    setShowSignUpSpinner(false);
  };

  // eslint-disable-next-line consistent-return
  const getAndVerifyUserAttributes = async () => {
    const userAttributes = await cognito.getUserAttributes();

    saveAPIKeys({
      editorKeyReadWriteMerge: userAttributes['custom:editor_api_key'],
      editorKeyRead: userAttributes['custom:editor_r'],
      importKeyReadWriteMerge: userAttributes['custom:import_api_key'],
      publishedKeyReadWriteMerge: userAttributes['custom:published_api_key'],
      publishedKeyRead: userAttributes['custom:published_r'],
      liveKeyReadWriteMerge: userAttributes['custom:live_api_key'],
      liveKeyRead: userAttributes['custom:live_r'],
      metaLiveKeyReadWriteMerge: userAttributes['custom:metalive_api_key'],
      metaLiveKeyRead: userAttributes['custom:metalive_r'],
    });

    setAuth(true);

    if (process.env.REACT_APP_ENV === 'development') return history.push('/profile');

    window.location.href = '/profile';
  };

  const handleConfirmation = async () => {
    setErrorMessage('');
    setShowCreatingAccount(true);
    setShowConfirmationForm(false);

    try {
      const res = await cognito.confirmRegistration(email, confirmationCode);
      if (res !== 'SUCCESS') { throw new Error('Cannot verify email'); }

      await cognito.signIn(email, password);
      await getAndVerifyUserAttributes();
    } catch (err: any) {
      console.error(err);
      setShowCreatingAccount(false);
      setShowConfirmationForm(true);
      setErrorMessage(err.message);
    }
  };

  const handleBackToLogin = () => history.push('/login');

  const handleResendConfirmationLink = () => {
    Auth.resendSignUp(email);
  };

  let confirmationForm = (
    <div className="signup-confirmation">
      <Typography variant="h5">
        Confirm registration
      </Typography>
      <Typography variant="body2">
        We have emailed you a confirmation code. Please enter it below to continue.
      </Typography>
      <TextField
        fullWidth
        id="confirmationCode"
        label="Confirmation code"
        value={confirmationCode}
        onChange={(e) => setConfirmationCode(e.target.value)}
        margin="normal"
      />
      <p style={style.errorMessage}>
        {errorMessage}
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleConfirmation}
        style={style.loginButton}
      >
        Confirm
      </Button>
    </div>
  );
  if (props.type === 'organization') {
    confirmationForm = (
      <div className="signup-confirmation">
        <Typography variant="h5">
          Confirm registration
        </Typography>
        <Typography variant="body2">
          We have emailed you a confirmation link. Click it to confirm registration.
        </Typography>
        <p style={style.errorMessage}>
          {errorMessage}
        </p>
        <Button
          variant="contained"
          onClick={handleResendConfirmationLink}
        >
          Resend confirmation
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToLogin}
          style={style.loginButton}
        >
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <LoginView>
      {showLoginForm
        && (
          <div className="signup">
            <form autoComplete="on">
              <Typography variant="h5">
                Sign Up
              </Typography>
              <TextField
                fullWidth
                id="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  sm={6}
                >
                  <TextField
                    fullWidth
                    id="firstname"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    margin="normal"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <TextField
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
              />

              <div
                className="agree-terms"
                style={style.agreeTerms}
              >
                <Checkbox
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                  value="agreeTerms"
                  color="primary"
                />
                I agree to Terms and Conditions.
              </div>
              <p style={style.errorMessage}>
                {errorMessage}
              </p>

              <Button
                style={style.loginButton}
                variant="contained"
                color="primary"
                onClick={handleSignUp}
              >
                {showSignUpSpinner
                  ? 'Signing Up'
                  : 'Sign Up'}
              </Button>
              <br />
            </form>
            Already have an account?&nbsp;
            <Link to="/login">
              Log in.
            </Link>
          </div>
        )}
      {showConfirmationForm
        && confirmationForm}
      {showCreatingAccount
        && (
          <div className="creating-account">
            <Typography variant="h5">
              Creating an account
            </Typography>
            <Typography variant="body2">
              This should take about 30 seconds.
              You will be redirected to your account once it&#39;s ready.
            </Typography>
            <br />
            <CircularProgress />
            {' '}
            <br />
            <span style={style.errorMessage}>
              {errorMessage}
            </span>
          </div>
        )}
    </LoginView>
  );
};

SignUp.defaultProps = {
  type: '',
};

export default SignUp;
