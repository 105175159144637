/* eslint-disable max-len */
import JSONLint from 'json-lint';

import {
  useEffect,
  useRef,
  FC,
  useState,
  MouseEvent,
  ChangeEvent,
  useCallback,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import defaultSmartmapSDKConfig from '@steerpath/common-resources/smart-sdk-default-config/steerpath_default_config.json';

import JSONEditor from './JSONEditor';
import ConfirmActionDialog from '../Dialog/ConfirmActionDialog';
import SnackbarMessage from '../Dialog/SnackbarMessage';

import history from '../history';
import './OrganizationEdit.scss';

import defaultLiveConfig from './live_default_config.json';
import defaultAzureClientCredentialsConfig from './azureClientCredentials_config.json';

import {
  CalendarProvider,
  IdentityProvider,
  UpdateOrgInput,
} from '../../configs/types/Org';
import { UserLevel } from '../../configs/types/User';
import {
  DialogElement,
  SnackbarMessageElement,
} from '../../configs/types/Common';
import { createEmailPattern } from '../../configs/graphql/emailPattern';
import {
  deleteOrg,
  updateOrganization,
} from '../../configs/graphql/org';
import MapComponent from './MapComponent';
import { defaultFeedbackConfig } from './defaultFeedbackConfig';
import { defaultExternalOrgMergeConfigs } from './defaultExternalOrgMergeConfigs';
import { defaultExtraConfig } from './defaultExtraConfig';

type OrganizationEditProps = {
  organization: UpdateOrgInput,
  isNewOrganization?: boolean
};

const OrganizationEdit: FC<OrganizationEditProps> = (
  { organization, isNewOrganization }: OrganizationEditProps,
) => {
  const [orgName, setOrgName] = useState(organization.orgName);
  const [
    calendarProvider,
    setCalendarProvider,
  ] = useState<CalendarProvider | undefined>(organization.calendarProvider);

  const [azureToken, setAzureToken] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [calendarBackendUrl, setCalendarBackendUrl] = useState('');
  const [idpFederationProvider, setIdpFederationProvider] = useState<IdentityProvider>('COGNITO');
  const [idpFederationTenantName, setIdpFederationTenantName] = useState<string>('');

  const [mixpanelToken, setMixpanelToken] = useState('');

  const [feedbackEmail, setFeedbackEmail] = useState(organization.feedbackEmail);
  const [typeformURL, setTypeformURL] = useState(organization.typeformURL);

  const [hasBitwards, setHasBitwards] = useState(organization.hasBitwards);
  const [hasBitwardsOAuth, setHasBitwardsOAuth] = useState(organization.hasBitwardsOAuth);

  const [liveSyncEnabled, setLiveSyncEnabled] = useState(organization.liveSyncEnabled);
  const [sensorSpeedUp, setSensorSpeedUp] = useState(organization.sensorSpeedUp);
  const [ghostBustingEnabled, setGhostBustingEnabled] = useState(organization.ghostBustingEnabled);
  const [carParkingEnabled, setCarParkingEnabled] = useState(organization.carParkingEnabled);
  const [
    feedbackConfig,
    setFeedbackConfig,
  ] = useState(JSON.stringify(organization.feedbackConfig, null, 2));
  const [
    liveConfig,
    setLiveConfig,
  ] = useState(JSON.stringify(organization.liveConfig, null, 2));
  const [
    smartmapSDKConfig,
    setSmartmapSDKConfig,
  ] = useState(JSON.stringify(organization.smartmapSDKConfig, null, 2));

  const [
    azureClientCredentials,
    setAzureClientCredentials,
  ] = useState(JSON.stringify(organization.azureClientCredentials, null, 2));

  const [
    extras,
    setExtras,
  ] = useState(JSON.stringify(organization.extras, null, 2));

  const [
    externalOrgMergeConfigs,
    setExternalOrgMergeConfigs,
  ] = useState(JSON.stringify(organization.externalOrgMergeConfigs, null, 2));

  const [adminInviteEmail, setAdminInviteEmail] = useState(isNewOrganization ? 'emailpw,' : '');

  // eslint-disable-next-line max-len
  const [anchorElCalendarProvider, setAnchorElCalendarProvider] = useState<null | HTMLElement>(null);
  const [anchorElIdpFederationProvider, setAnchorElIdpFederationProvider] = useState<null | HTMLElement>(null);

  const [dialogType, setDialogType] = useState('');
  const dialogRef = useRef<DialogElement>();

  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();
  const calendarProviders = [
    'GOOGLE',
    'STEERPATH',
    'OFFICE365',
    'TIMEWORKS',
  ];
  const idpFederationProviders = [
    'AZURE', 'COGNITO', 'GOOGLE', 'SAML',
  ];

  useEffect(() => {
    if (organization?.googleToken) {
      setGoogleToken(organization?.googleToken as string);
    }
    if (organization?.calendarBackendUrl) {
      setCalendarBackendUrl(organization?.calendarBackendUrl as string);
    }
    if (organization?.azureToken) {
      setAzureToken(organization?.azureToken as string);
    }
    if (organization?.mixpanelToken) {
      setMixpanelToken(organization?.mixpanelToken as string);
    }
    if (organization?.idpFederationProvider) {
      setIdpFederationProvider(organization?.idpFederationProvider as IdentityProvider);
    }
    if (organization?.idpFederationTenantName) {
      setIdpFederationTenantName(organization?.idpFederationTenantName as string);
    }
  }, [organization]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputType = event.target.id;
    if (inputType === 'orgName') {
      setOrgName(event.target.value);
    } else if (inputType === 'feedbackEmail') {
      setFeedbackEmail(event.target.value);
    } else if (inputType === 'adminInviteEmail') {
      setAdminInviteEmail(event.target.value);
    } else if (inputType === 'typeformURL') {
      setTypeformURL(event.target.value);
    } else if (inputType === 'azureToken') {
      setAzureToken(event.target.value);
    } else if (inputType === 'googleToken') {
      setGoogleToken(event.target.value);
    } else if (inputType === 'calendarBackendUrl') {
      setCalendarBackendUrl(event.target.value);
    } else if (inputType === 'mixpanelToken') {
      setMixpanelToken(event.target.value);
    } else if (inputType === 'idpFederationTenantName') {
      setIdpFederationTenantName(event.target.value);
    }
  };

  const handleBitwardsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasBitwards(event.target.value === 'hasBitwards');
    setHasBitwardsOAuth(event.target.value === 'hasBitwardsOAuth');
  };

  const onJSONEditorChange = (type: string, value: string) => {
    if (type === 'feedbackConfig') {
      setFeedbackConfig(value);
    } else if (type === 'liveConfig') {
      setLiveConfig(value);
    } else if (type === 'smartmapSDKConfig') {
      setSmartmapSDKConfig(value);
    } else if (type === 'azureClientCredentials') {
      setAzureClientCredentials(value);
    } else if (type === 'extras') {
      setExtras(value);
    } else if (type === 'externalOrgMergeConfigs') {
      setExternalOrgMergeConfigs(value);
    }
  };

  const onMapComponentChange = (type: string, value: string) => {
    let configBase = JSON.parse(smartmapSDKConfig);
    if (type === 'viewProperties') {
      configBase.services.smartmap.default.viewProperties = JSON.parse(value);
    } else if (type === 'kiosk') {
      configBase.services.kiosk = JSON.parse(value);
    }
    configBase = JSON.stringify(configBase, null, 2);
    setSmartmapSDKConfig(configBase);
  };

  const handleCloseMenu = () => {
    setAnchorElCalendarProvider(null);
    setAnchorElIdpFederationProvider(null);
  };

  const handleMenuSelect = useCallback((item: string, menuType: 'calendarProvider' | 'sharedUserDataTableVersion' | 'idpFederationProvider') => {
    if (menuType === 'calendarProvider') {
      setCalendarProvider((item as CalendarProvider));
    } else if (menuType === 'idpFederationProvider') {
      setIdpFederationProvider((item as IdentityProvider));
    }
    handleCloseMenu();
  }, []);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>, menuType: 'calendarProvider' | 'sharedUserDataTableVersion' | 'idpFederationProvider') => {
    if (menuType === 'calendarProvider') {
      setAnchorElCalendarProvider(event.currentTarget);
    } else if (menuType === 'idpFederationProvider') {
      setAnchorElIdpFederationProvider(event.currentTarget);
    }
  }, []);

  const validateFormFields = () => {
    if (!orgName) throw new Error('Misising Organization Name');
    if (!calendarProvider) throw new Error('Missing Calendar Provider');
    if (!idpFederationProvider) throw new Error('Missing Login Provider');
    // if (!sharedUserDataTableVersion) throw new Error('SharedUserData-table Version');
    if (JSONLint(feedbackConfig).error) {
      throw new Error('Feedback Config not valid JSON. Try setting default or {}');
    }

    if (JSONLint(liveConfig).error) {
      throw new Error('Live Config config not valid JSON. Try setting default or {}');
    }

    if (JSONLint(smartmapSDKConfig).error) {
      throw new Error('Smart Map Config config not valid JSON. Try setting default or {}');
    }

    if (JSONLint(azureClientCredentials).error) {
      throw new Error('Azure Service Principal config not valid JSON. Try setting default or {}');
    }

    if (JSONLint(externalOrgMergeConfigs).error) {
      throw new Error('externalOrgMergeConfigs config not valid JSON. Try setting default or []');
    }

    if (JSONLint(extras).error) {
      throw new Error('externalOrgMergeConfigs config not valid JSON. Try setting default or {}');
    }

    // New created org
    if (isNewOrganization) {
      if (!adminInviteEmail) throw new Error('Missing Admin Invite email');
    }
  };

  const handleOpenSaveDialog = () => {
    try {
      validateFormFields();
    } catch (err: any) {
      setSnackbarMessage(err.message);
      snackbarRef?.current?.handleOpen();
      return;
    }
    setDialogType('saveDialogOrganization');
    dialogRef?.current?.handleOpen();
  };

  const handleOpenDeleteDialog = () => {
    setDialogType('deleteDialogOrganization');
    dialogRef?.current?.handleOpen();
  };

  const handleOpenSetDefaultConfigDialog = (type: string) => {
    /**
     * setDefault_feedbackConfig
     * setDefault_liveConfig
     * setDefault_smartmapSDKConfig
     * setDefault_externalOrgMergeConfigs
     * setDefault_extras
     */
    setDialogType(`setDefault_${type}`);
    dialogRef?.current?.handleOpen();
  };

  const handleCancel = () => {
    history.push('/organization-management');
  };

  const handleDelete = async () => {
    if (!organization.orgId) return;

    setShowLoginSpinner(true);
    const { data, errors } = await deleteOrg(organization.orgId);
    setShowLoginSpinner(false);
    if (errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach((error: Error) => {
        errorMessages.push(error.message);
      });

      setSnackbarMessage(`Could not delete organization ${errorMessages}`);
      snackbarRef?.current?.handleOpen();
    } else if (data && data.deleteOrg) {
      history.push('/organization-management');
    }
  };

  const handleSave = async () => {
    setShowLoginSpinner(true);
    const organizationInput: UpdateOrgInput = {
      orgName,
      calendarProvider: (calendarProvider as CalendarProvider),
      googleToken,
      azureToken,
      calendarBackendUrl,
      feedbackConfig: JSON.parse(feedbackConfig),
      feedbackEmail,
      typeformURL,
      hasBitwards,
      smartmapSDKConfig: JSON.parse(smartmapSDKConfig),
      liveConfig: JSON.parse(liveConfig),
      liveSyncEnabled,
      ghostBustingEnabled,
      carParkingEnabled,
      // sharedUserDataTableVersion,
      azureClientCredentials: JSON.parse(azureClientCredentials),
      hasBitwardsOAuth,
      extras: JSON.parse(extras),
      externalOrgMergeConfigs: JSON.parse(externalOrgMergeConfigs),
      sensorSpeedUp,
      mixpanelToken,
      idpFederationProvider,
      idpFederationTenantName,
    };

    let response = null;
    if (!isNewOrganization) {
      organizationInput.orgId = organization.orgId;
      response = await updateOrganization(organizationInput, false);
    } else {
      response = await updateOrganization(organizationInput, true);
    }
    console.log('response', response);

    if (response) {
      if (response.data?.createOrg || response.data?.updateOrg) {
        // invite admin
        if (adminInviteEmail) {
          const sendInviteInput = {
            addressPattern: adminInviteEmail,
            defaultUserLevel: UserLevel.ADMIN,
            orgId: isNewOrganization
              ? response.data?.createOrg?.orgId
              : response.data?.updateOrg?.orgId,
          };
          try {
            const createEmailPatternResponse = await createEmailPattern(sendInviteInput);
            if (!createEmailPatternResponse?.data?.createEmailPattern) {
              // if failed on the org creation redirect user to main view
              // from which they can re-opem the org edit view adn try just the standard update
              if (isNewOrganization) {
                setDialogType('failed_to_invite_new_admin_on_organization_create');
                dialogRef?.current?.handleOpen();
              } else {
                setSnackbarMessage('Could not invite admin to the Organization');
                snackbarRef?.current?.handleOpen();
              }
            }
          } catch (error) {
            setSnackbarMessage('Could not invite admin to the Organization');
            snackbarRef?.current?.handleOpen();
          }
        }
        setShowLoginSpinner(false);
        history.push('/organization-management');
      }
    } else {
      setShowLoginSpinner(false);
      setSnackbarMessage('Something went wrong when updating Organization');
      snackbarRef?.current?.handleOpen();
    }
    setShowLoginSpinner(false);
  };

  const onDialogConfirm = (type: string) => {
    if (type === 'saveDialogOrganization') {
      handleSave();
    } else if (type === 'deleteDialogOrganization') {
      handleDelete();
    } else if (type === 'failed_to_invite_new_admin_on_organization_create') {
      handleCancel();
    } else if (type === 'setDefault_feedbackConfig') {
      setFeedbackConfig(JSON.stringify(defaultFeedbackConfig, null, 2));
    } else if (type === 'setDefault_liveConfig') {
      setLiveConfig(JSON.stringify(defaultLiveConfig, null, 2));
    } else if (type === 'setDefault_smartmapSDKConfig') {
      setSmartmapSDKConfig(JSON.stringify(defaultSmartmapSDKConfig, null, 2));
    } else if (type === 'setDefault_azureClientCredentials') {
      setAzureClientCredentials(JSON.stringify(defaultAzureClientCredentialsConfig, null, 2));
    } else if (type === 'setDefault_externalOrgMergeConfigs') {
      setExternalOrgMergeConfigs(JSON.stringify([], null, 2));
    } else if (type === 'setDefault_extras') {
      setExtras(JSON.stringify(defaultExtraConfig, null, 2));
    }
    dialogRef?.current?.handleClose();
  };

  const onDialogCancel = (type: string) => {
    if (type === 'failed_to_invite_new_admin_on_organization_create') {
      handleCancel();
    }
    dialogRef?.current?.handleClose();
  };
  let defaultBitwardsValue = 'none';
  if (hasBitwards) {
    defaultBitwardsValue = 'hasBitwards';
  } else if (hasBitwardsOAuth) {
    defaultBitwardsValue = 'hasBitwardsOAuth';
  }

  return (
    <div className="edit-organization-view">

      <Paper
        elevation={3}
        className="edit-organization-paper"
      >
        <Typography variant="h4">{!isNewOrganization ? 'Edit Organization' : 'Add New Organization'}</Typography>
        <Typography variant="body2">{organization.orgId ? `Id: ${organization.orgId}` : ''}</Typography>

        <div className="edit-organization-content">

          <Typography variant="h6">Name</Typography>
          <TextField
            id="orgName"
            value={orgName}
            onChange={handleOnChange}
            placeholder="Organization Name"
            margin="dense"
          />

          <Typography variant="h6">Login</Typography>
          <div>
            <Typography variant="body1">Login Provider and Tenant Name</Typography>
            <Button
              variant="contained"
              onClick={(event) => {
                handleOpenMenu(event, 'idpFederationProvider');
              }}
            >
              {idpFederationProvider || 'Set Login Provider'}
            </Button>
            <Menu
              anchorEl={anchorElIdpFederationProvider}
              keepMounted
              open={Boolean(anchorElIdpFederationProvider)}
              onClose={handleCloseMenu}
            >
              {idpFederationProviders.map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => handleMenuSelect(item, 'idpFederationProvider')}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <TextField
            id="idpFederationTenantName"
            disabled={calendarProvider === undefined}
            value={idpFederationTenantName}
            onChange={handleOnChange}
            placeholder="company_inc"
            helperText="Tenant Name"
            margin="dense"
          />

          <Typography variant="h6">Booking</Typography>
          <div>
            <Button
              variant="contained"
              onClick={(event) => {
                handleOpenMenu(event, 'calendarProvider');
              }}
            >
              {calendarProvider || 'Set Calendar Provider'}
            </Button>
            <Menu
              anchorEl={anchorElCalendarProvider}
              keepMounted
              open={Boolean(anchorElCalendarProvider)}
              onClose={handleCloseMenu}
            >
              {calendarProviders.map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => handleMenuSelect(item, 'calendarProvider')}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>

          <TextField
            id="calendarBackendUrl"
            disabled={calendarProvider === undefined}
            value={calendarBackendUrl}
            onChange={handleOnChange}
            placeholder="calendarBackendUrl"
            helperText="calendarBackendUrl"
            margin="dense"
          />
          <TextField
            id="azureToken"
            disabled={calendarProvider === undefined}
            value={azureToken}
            onChange={handleOnChange}
            placeholder="azureToken"
            helperText="azureToken"
            margin="dense"
          />
          <TextField
            id="googleToken"
            disabled={calendarProvider === undefined}
            value={googleToken}
            onChange={handleOnChange}
            placeholder="googleToken"
            helperText="googleToken"
            margin="dense"
          />

          <Typography variant="h6">Azure Service Principal Config</Typography>
          <Typography variant="body2">Config</Typography>
          <JSONEditor
            type="azureClientCredentials"
            value={azureClientCredentials}
            onChange={onJSONEditorChange}
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('azureClientCredentials')}>Set Default</Button>
          </div>

          <Typography variant="h6">Bitwards</Typography>
          <FormControl>
            <RadioGroup
              aria-label="bitwards"
              defaultValue={defaultBitwardsValue}
              name="radio-buttons-group"
              onChange={handleBitwardsChange}
              value={defaultBitwardsValue}
            >
              <FormControlLabel
                value="hasBitwards"
                control={<Radio />}
                label="Use Bitwards"
              />
              <FormControlLabel
                value="hasBitwardsOAuth"
                control={<Radio />}
                label="Use Bitwards OAuth"
              />
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="No Bitwards"
              />
            </RadioGroup>
          </FormControl>

          <Typography variant="h6">Feedback</Typography>
          <TextField
            id="feedbackEmail"
            value={feedbackEmail}
            onChange={handleOnChange}
            placeholder="Feedback Email"
            margin="dense"
          />
          <br />
          <TextField
            id="typeformURL"
            value={typeformURL}
            onChange={handleOnChange}
            placeholder="Typeform URL"
            margin="dense"
          />
          <Typography variant="body2">Feedback Config</Typography>
          <JSONEditor
            type="feedbackConfig"
            value={feedbackConfig}
            onChange={onJSONEditorChange}
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('feedbackConfig')}>Set Default</Button>
          </div>

          <Typography variant="h6">Ghost Busting</Typography>
          <div className="edit-organization-check-box-row">
            <Checkbox
              style={{ padding: 0 }}
              checked={ghostBustingEnabled as boolean}
              onChange={(e) => setGhostBustingEnabled(e.target.checked)}
              value="ghostBustingEnabled"
              color="primary"
            />
            <Typography
              variant="body1"
              style={{ paddingLeft: 8 }}
            >
              Enable Ghost Busting
            </Typography>
          </div>

          <Typography variant="h6">Parking</Typography>
          <div className="edit-organization-check-box-row">
            <Checkbox
              style={{ padding: 0 }}
              checked={carParkingEnabled as boolean}
              onChange={(e) => setCarParkingEnabled(e.target.checked)}
              value="carParkingEnabled"
              color="primary"
            />
            <Typography
              variant="body1"
              style={{ paddingLeft: 8 }}
            >
              Enable Parking
            </Typography>
          </div>

          <Typography variant="h6">Live</Typography>
          <div className="edit-organization-check-box-row">
            <Checkbox
              style={{ padding: 0 }}
              checked={liveSyncEnabled as boolean}
              onChange={(e) => setLiveSyncEnabled(e.target.checked)}
              value="liveSyncEnabled"
              color="primary"
            />
            <Typography
              variant="body1"
              style={{ paddingLeft: 8 }}
            >
              Enable live synchronization
            </Typography>
          </div>
          <div className="edit-organization-check-box-row">
            <Checkbox
              style={{ padding: 0 }}
              checked={sensorSpeedUp as boolean}
              onChange={(e) => setSensorSpeedUp(e.target.checked)}
              value="sensorSpeedUp"
              color="primary"
            />
            <Typography
              variant="body1"
              style={{ paddingLeft: 8 }}
            >
              Enable sensor speed-up
            </Typography>
          </div>

          <Typography variant="body2">Live Config</Typography>
          <JSONEditor
            type="liveConfig"
            value={liveConfig}
            onChange={onJSONEditorChange}
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('liveConfig')}>
              Set Default
            </Button>
          </div>

          <Typography variant="h6">Map</Typography>
          <Typography variant="body2">Smart Map Config</Typography>
          <JSONEditor
            type="smartmapSDKConfig"
            value={smartmapSDKConfig}
            onChange={onJSONEditorChange}
            height="400px"
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('smartmapSDKConfig')}>Set Default</Button>
          </div>
          <Typography variant="h6">Mergator</Typography>
          <Typography variant="body2">Mergator Configuration</Typography>
          <JSONEditor
            type="externalOrgMergeConfigs"
            value={externalOrgMergeConfigs}
            onChange={onJSONEditorChange}
            height="300px"
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('externalOrgMergeConfigs')}>Set Default</Button>
            <Button onClick={() => {
              const defaultConfig = JSON.stringify(defaultExternalOrgMergeConfigs, null, 2);
              setExternalOrgMergeConfigs(defaultConfig);
            }}
            >
              Set Template
            </Button>

          </div>

          {organization.publishedKeyRead
            && (
              <MapComponent
                apiKey={organization.publishedKeyRead}
                onChange={onMapComponentChange}
              />
            )}
          <Typography variant="h6">Extras</Typography>
          <Typography variant="body2">Extras</Typography>
          <JSONEditor
            type="extras"
            value={extras}
            onChange={onJSONEditorChange}
            height="100px"
          />
          <div className="edit-organization-check-box-row">
            <Button onClick={() => handleOpenSetDefaultConfigDialog('extras')}>Set default</Button>
          </div>
          <Typography variant="h6">Mixpanel</Typography>
          <TextField
            id="mixpanelToken"
            value={mixpanelToken}
            onChange={handleOnChange}
            placeholder="Mixpanel token"
            margin="dense"
          />
          <Typography variant="h6">Invite Admin</Typography>
          <div className="edit-organization-check-box-row">
            <div style={{ width: '80%', maxWidth: '480px' }}>
              <Typography variant="body2">
                Invite admin to the system either for
                the first time or send a new invite.
              </Typography>
              <TextField
                id="adminInviteEmail"
                value={adminInviteEmail}
                onChange={handleOnChange}
                placeholder="Email of the admin user"
                margin="dense"
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <div className="edit-organization-bottom">
            <div>
              {
                !isNewOrganization
                  ? (
                    <Button onClick={handleOpenDeleteDialog}>
                      DELETE
                    </Button>
                  )
                  : <div />
              }
              <Button
                variant="outlined"
                style={{ marginLeft: 32, marginRight: 8 }}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenSaveDialog}
              >
                {!isNewOrganization ? 'UPDATE' : 'CREATE'}
              </Button>
            </div>

          </div>
        </div>

        <SnackbarMessage
          message={snackbarMessage}
          ref={snackbarRef}
        />

        <ConfirmActionDialog
          type={dialogType}
          ref={dialogRef}
          onConfirm={onDialogConfirm}
          onCancel={onDialogCancel}
        />
        {showLoginSpinner
          ? (
            <Dialog
              open
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Loading
              </DialogTitle>
              <DialogContent>
                <CircularProgress size={80} />
              </DialogContent>
            </Dialog>
          ) : <div />}
      </Paper>
    </div>
  );
};

OrganizationEdit.defaultProps = {
  isNewOrganization: false,
};

export default OrganizationEdit;
