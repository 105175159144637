import ReactDOM from 'react-dom';

import packageJson from '../package.json';

import App from './App';

// eslint-disable-next-line no-console
console.log(
  'Running ',
  packageJson.name,
  ' in ',
  process.env.REACT_APP_ENV,
  'Version ',
  packageJson.version,
);
ReactDOM.render(<App />, document.getElementById('root'));
