import {
  CSSProperties,
  useState,
  useEffect,
  ReactElement,
} from 'react';

import ApplicationLinks from './ApplicationLinks';
import BuildingSelector from './BuildingSelector';
import SelectApplication from './SelectApplication';
import Profile from './Profile';

const style: {
  toolbar: CSSProperties,
  toolbarTop: CSSProperties,
  toolbarBottom: CSSProperties,
  leftSide: CSSProperties,
  rightSide: CSSProperties,
} = {
  toolbar: {
    position: 'static',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    color: '#fff',
    background:
      'linear-gradient(72deg, rgba(52,162,198,1) 0%, rgba(72,209,210,1) 100%, rgba(111,213,214,1) 100%)',
    borderBottom: '1px solid #eee',
  },
  toolbarTop: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    overflowX: 'auto',
    marginBottom: '8px',
  },
  toolbarBottom: {
    height: '48px',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
    marginLeft: '16px',
    color: 'white',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '16px',
  },
};

interface Building {
  title: string,
  buildingRef: string,
}

type SteerpathToolbarProps = {
  applicationName: string,
  selectMenuItems: Building[],
  selectMenuClick: (buildingRef: string) => void,
  defaultSelectedItem: string,
  history: any,
};

export default function SteerpathToolbar({
  applicationName,
  selectMenuItems,
  selectMenuClick,
  defaultSelectedItem,
  history,
}: SteerpathToolbarProps): ReactElement {
  const [activeBuilding, setActiveBuilding] = useState(defaultSelectedItem);

  const handleBuildingSelectorChange = (buildingRef: string) => {
    setActiveBuilding(buildingRef);
    selectMenuClick(buildingRef);
  };

  useEffect(() => {
    if (defaultSelectedItem !== activeBuilding) setActiveBuilding(defaultSelectedItem);
  }, [defaultSelectedItem, activeBuilding]);

  return (
    <div
      className="steerpath-toolbar"
      style={style.toolbar}
    >
      <div
        className="top"
        style={style.toolbarTop}
      >
        <div
          className="steerpath-toolbar-left"
          style={style.leftSide}
        >
          {selectMenuItems.length > 0
            && (
            <div className="steerpath-toolbar-select">
              <BuildingSelector
                buildings={selectMenuItems}
                handleBuildingSelectorChange={handleBuildingSelectorChange}
                activeBuilding={activeBuilding}
              />
            </div>
            )}
        </div>

        <div
          className="steerpath-toolbar-right"
          style={style.rightSide}
        >
          <SelectApplication
            applicationName={applicationName}
            history={history}
          />
          <Profile />
        </div>
      </div>
      <div className="bottom">
        <ApplicationLinks
          applicationName={applicationName}
          history={history}
        />
      </div>
    </div>
  );
}
