import React from 'react';
import Paper from '@material-ui/core/Paper';

interface StyleProps {
  loginView: React.CSSProperties;
  prompt: React.CSSProperties;
}

const style: StyleProps = {
  loginView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(#34a2c6, #6fd5d6)',
  },
  prompt: {
    width: '360px',
    height: '480px',
    display: 'flex',
    padding: '16px',
  },
};

interface LoginViewProps {
  children: React.ReactNode;
}

const LoginView: React.FC<LoginViewProps> = ({ children }: LoginViewProps) => (
  <div style={style.loginView}>
    <Paper style={style.prompt}>
      {children}
    </Paper>
  </div>
);

export default LoginView;
