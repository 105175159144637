import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';

import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  Button,
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

import MapIcon from '@material-ui/icons/Map';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RoomIcon from '@material-ui/icons/Room';

import ApartmentIcon from '@material-ui/icons/Apartment';
import ImageIcon from '@material-ui/icons/Image';

import './styles.scss';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { mapboxMap: any; }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { steerpathMap: any; }

}
window.mapboxMap = window.mapboxMap || {};
window.steerpathMap = window.steerpathMap || {};

type MapLayer = {
  id: string,
  source: string,
  type: string,
  layout?: {
    visibility?: string
  }
};

const MapLayers = forwardRef((props, ref) => {
  console.log('props', props);
  const [mapLayers, setMaplayers] = React.useState<Array<MapLayer>>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checked, setChecked] = React.useState<Array<number>>([]);
  const [isOutdoorVisible, setIsOutdoorVisible] = React.useState(true);
  const [isIndoorVisible, setIsIndoorVisible] = React.useState(true);
  const [isExtrusionVisible, setIsExtrusionVisible] = React.useState(true);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (id: string, index: number) => () => {
    if (id === 'outdoor') {
      const { layers } = window.mapboxMap.getStyle();
      const visibility = isOutdoorVisible === true ? 'none' : 'visible';
      layers.forEach((layer: MapLayer) => {
        if (layer.source === 'openmaptiles') {
          window.mapboxMap.setLayoutProperty(layer.id, 'visibility', visibility);
        }
      });
      setIsOutdoorVisible(!isOutdoorVisible);
    } else if (id === 'indoor') {
      const { layers } = window.mapboxMap.getStyle();
      const visibility = isIndoorVisible === true ? 'none' : 'visible';
      layers.forEach((layer: MapLayer) => {
        if (layer.source === 'blueprint') {
          window.mapboxMap.setLayoutProperty(layer.id, 'visibility', visibility);
        }
      });
      setIsIndoorVisible(!isIndoorVisible);
    } else if (id === 'extrustion') {
      const { layers } = window.mapboxMap.getStyle();
      const visibility = isExtrusionVisible === true ? 'none' : 'visible';
      layers.forEach((layer: MapLayer) => {
        if (layer.type === 'fill-extrusion') {
          window.mapboxMap.setLayoutProperty(layer.id, 'visibility', visibility);
        }
      });
      setIsExtrusionVisible(!isExtrusionVisible);
    } else {
      const currentIndex = checked.indexOf(index);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(index);
        window.mapboxMap.setLayoutProperty(id, 'visibility', 'visible');
      } else {
        newChecked.splice(currentIndex, 1);
        window.mapboxMap.setLayoutProperty(id, 'visibility', 'none');
      }

      setChecked(newChecked);
    }
  };

  const initializeMap = () => {
    const { layers } = window.mapboxMap.getStyle();
    const styleLayers: Array<MapLayer> = [];
    const visibleLayers: Array<number> = [];
    layers.forEach((layer: MapLayer, index: number) => {
      styleLayers.push({
        id: layer.id,
        source: layer.source,
        type: layer.type,
        layout: {
          visibility: layer.layout?.visibility ? layer.layout?.visibility : 'visible',
        },
      });
      if (layer.layout?.visibility !== 'none') {
        visibleLayers.push(index);
      }
    });
    setMaplayers(styleLayers);
    setChecked(visibleLayers);
  };

  useImperativeHandle(
    ref,
    () => ({
      init() {
        initializeMap();
      },
    }),
  );

  const layers = mapLayers.map((layer, index) => {
    let listIcon = <MapIcon />;
    if (layer.type === 'fill') {
      listIcon = <FormatPaintIcon />;
    } else if (layer.type === 'line') {
      listIcon = <CheckBoxOutlineBlankIcon />;
    } else if (layer.type === 'symbol') {
      listIcon = <RoomIcon />;
    }
    return (
      <ListItem key={layer.id}>
        <ListItemIcon>
          {listIcon}
        </ListItemIcon>

        <ListItemText
          primary={layer.id}
          secondary={`Source: ${layer.source}`}
        />
        <ListItemSecondaryAction>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={!!checked.includes(index)}
              onChange={handleToggle(layer.id, index)}
            />
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  const customLayers = ['outdoor', 'indoor', 'extrustion'].map((layer) => {
    let listIcon = <MapIcon />;
    let name = '';
    let status = true;
    if (layer === 'outdoor') {
      name = 'Outdoor';
      listIcon = <ImageIcon />;
      status = isOutdoorVisible;
    } else if (layer === 'indoor') {
      name = 'Indoor';
      listIcon = <ApartmentIcon />;
      status = isIndoorVisible;
    } else if (layer === 'extrustion') {
      name = '3D Elements';
      listIcon = <MapIcon />;
      status = isExtrusionVisible;
    }
    return (
      <ListItem key={layer}>
        <ListItemIcon>
          {listIcon}
        </ListItemIcon>

        <ListItemText primary={name} />

        <ListItemSecondaryAction>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={status === true}
              onChange={handleToggle(layer, -1)}
            />
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
  return (

    <FormControl component="fieldset">

      <Button onClick={handleClick}>
        Toggle Style Layers
      </Button>
      <Menu
        id="layers-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <List dense>

          {customLayers}

          <hr />

          {layers}
        </List>
      </Menu>
    </FormControl>
  );
});

MapLayers.displayName = 'MapLayers';

export default MapLayers;
