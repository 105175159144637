import {
  FC,
  useEffect,
  useCallback,
} from 'react';
import 'brace/mode/json';
import 'brace/snippets/json';
import 'brace/ext/language_tools';
import 'brace/ext/searchbox';
import 'brace/theme/dreamweaver';
import 'brace/theme/eclipse';
import { Button, Typography } from '@material-ui/core';
import { LocalApiKeyDict } from '../CognitoLogin/utils';
import { MAPDATA_URL } from '../../config';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { mapboxMap: any; }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { steerpathMap: any; }
}

interface MapComponentProps {
  onChange: (type: string, value: string) => void;
  apiKey: string | null;
}

interface Bound {
  _sw: { lng: number, lat: number };
  _ne: { lng: number, lat: number };
}

window.mapboxMap = window.mapboxMap || {};
window.steerpathMap = window.steerpathMap || {};

const MapComponent: FC<MapComponentProps> = (props: MapComponentProps) => {
  const handleSetViewProperties = () => {
    const bounds = window.mapboxMap.getBounds();
    const viewProperties = {
      bearing: window.mapboxMap.getBearing(),
      pitch: window.mapboxMap.getPitch(),
      layerIndex: window.steerpathMap.getActiveBuildingLayerIndex(),
      buildingRef: window.steerpathMap.getActiveBuildingBuildingRef(),
      bounds: {
        sw: bounds._sw,
        ne: bounds._ne,
      },
    };
    props.onChange('viewProperties', JSON.stringify(viewProperties));
  };

  const handleSetKiosk = () => {
    const bounds = window.mapboxMap.getBounds();
    const kiosk = {
      defaultCamera: {
        bearing: window.mapboxMap.getBearing(),
        pitch: window.mapboxMap.getPitch(),
        // eslint-disable-next-line max-len
        layerIndex: window.steerpathMap.getActiveBuildingLayerIndex() !== null ? window.steerpathMap.getActiveBuildingLayerIndex() : 0,
        buildingRef: window.steerpathMap.getActiveBuildingBuildingRef() !== null ? window.steerpathMap.getActiveBuildingBuildingRef() : 'buildingRef',
        bounds: {
          sw: bounds._sw,
          ne: bounds._ne,
        },
      },
      share: {
        hash: false,
        qrCodeShare: {
          enabled: false,
          qrCodeURL: 'https://kiosk.steerpath.com/',
          qrCodePath: '/KIOSK_NAME/index.html',
        },
        copyLinkToClipboard: false,
      },
    };
    props.onChange('kiosk', JSON.stringify(kiosk));
  };

  const getMapPinLocations = (bounds: Bound) => {
    const data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            title: 'sw',
          },
          geometry: {
            type: 'Point',
            coordinates: [bounds._sw.lng, bounds._sw.lat],
          },
        },
        {
          type: 'Feature',
          properties: {
            title: 'ne',
          },
          geometry: {
            type: 'Point',
            coordinates: [bounds._ne.lng, bounds._ne.lat],
          },
        },
      ],
    };
    return data;
  };

  const initializeMap = useCallback((apiKeys: LocalApiKeyDict) => {
    let defaultMapView = {
      center: {
        lng: 0,
        lat: 0,
      },
      bearing: 0,
      zoom: 0,
      pitch: 0,
    };
    const localMapView = localStorage.getItem('mapView');
    if (localMapView) {
      defaultMapView = JSON.parse(localMapView);
    }
    const mapApiKey = (props.apiKey ? props.apiKey : apiKeys.PUBLISHED_API_KEY_R);
    const styleUrl = `${MAPDATA_URL}/default.json?access_token=${mapApiKey}`;

    const mapboxMap = new window.mapboxgl.Map({
      container: 'mapboxMap',
      style: styleUrl,
      center: defaultMapView.center,
      zoom: defaultMapView.zoom,
      bearing: defaultMapView.bearing,
      pitch: defaultMapView.pitch,
      attributionControl: false,
      bearingSnap: 0,
      maxZoom: 22,
    });

    const steerpathMap = new window.steerpath.SteerpathMap(
      mapboxMap,
      mapApiKey,
    );

    window.mapboxMap = mapboxMap;
    window.steerpathMap = steerpathMap;

    mapboxMap.addControl(
      new window.steerpath.FloorSwitcherControl(steerpathMap),
      'bottom-right',
    );

    mapboxMap.on('moveend', () => {
      localStorage.setItem('mapView', JSON.stringify({
        center: mapboxMap.getCenter(),
        bearing: mapboxMap.getBearing(),
        zoom: mapboxMap.getZoom(),
        pitch: mapboxMap.getPitch(),
      }));

      if (mapboxMap.getSource('boundsFeatures')) {
        const bounds = mapboxMap.getBounds();
        mapboxMap.getSource('boundsFeatures').setData(getMapPinLocations(bounds));
      }
    });

    mapboxMap.once('load', () => {
      const bounds = mapboxMap.getBounds();
      mapboxMap.addSource('boundsFeatures', {
        type: 'geojson',
        data: getMapPinLocations(bounds),
      });
      mapboxMap.addLayer({
        id: 'buondsFeatureCircle',
        type: 'circle',
        source: 'boundsFeatures',
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-color': '#fff',
          'circle-stroke-color': {
            base: 1,
            type: 'categorical',
            property: 'title',
            default: '#34a2c6',
            stops: [
              ['sw', '#9b59b6'],
              ['ne', '#f1c40f'],
            ],
          },
          'circle-pitch-alignment': 'map',
        },
      });

      mapboxMap.addLayer({
        id: 'buondsFeatureCircleSymbol',
        type: 'symbol',
        source: 'boundsFeatures',
        metadata: {
        },
        layout: {
          visibility: 'visible',
          'icon-image': {
            base: 1,
            type: 'categorical',
            property: 'title',
            default: 'pin_a',
            stops: [
              ['sw', 'pin_a'],
              ['ne', 'pin_b'],
            ],
          },
          'icon-offset': [0, -16],
        },
        paint: {

        },
      });
    });
  }, [props.apiKey]);

  useEffect(() => {
    const apiKeys = JSON.parse(localStorage.getItem('API_KEYS') || '{}');
    initializeMap(apiKeys);
  }, [initializeMap]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        marginTop: '16px',
        height: '320px',
        flex: 5,
      }}
    >
      <div
        id="mapboxMap"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#e2e0d7',
          flex: 3,
        }}
      />

      <div
        style={{
          marginLeft: '24px',
          flex: 2,
        }}
      >
        <Typography
          variant="h6"
          style={{ marginTop: 0 }}
        >
          Settings
        </Typography>

        <Typography variant="body2">View Properties</Typography>
        <Button
          variant="contained"
          onClick={handleSetViewProperties}
        >
          Set View Properties
        </Button>

        <Typography variant="body2">Kiosk</Typography>
        <Button
          variant="contained"
          onClick={handleSetKiosk}
        >
          Set Kiosk Properties
        </Button>

      </div>
    </div>
  );
};

MapComponent.displayName = 'MapComponent';

export default MapComponent;
