import { FC, useState } from 'react';

type LinkApplicationMapping = {
  routerLink: string,
  href: string,
  application: string,
};

type SelectApplicationProps = {
  applicationName: string,
  history: any,
};

const SelectApplication: FC<SelectApplicationProps> = ({
  applicationName,
  history,
}: SelectApplicationProps) => {
  const [hover, setHover] = useState('');
  const links = ['Maps', 'Insights'];
  // applicationName: Steerpath, liveDashboard, insightsDashboard, poiEditor, cadImport
  if (localStorage.getItem('isPowerUser') === 'true') {
    links.push('Live');
  }

  const getLinkBasedOnApplicatioName = () => {
    if (applicationName === 'poiEditor') {
      return 'maps';
    }

    if (applicationName === 'cadImport') {
      return 'maps';
    }

    if (applicationName === 'liveDashboard') {
      return 'live';
    }

    if (applicationName === 'insightsDashboard') {
      return 'insights';
    }

    if (applicationName === 'Steerpath') {
      return 'profile';
    }

    return '';
  };

  const handleClick = (link: string) => {
    const linkApplicationMapping: { [key: string]: LinkApplicationMapping } = {
      Maps: {
        routerLink: 'buildings',
        href: 'buildings',
        application: 'poiEditor',
      },
      Insights: {
        routerLink: 'beacon-health',
        href: 'beacon-health',
        application: 'insightsDashboard',
      },
      Live: {
        routerLink: 'live',
        href: 'live',
        application: 'liveDashboard',
      },
    };

    // see if current application is same
    // as the one where we are about to navigate
    if (linkApplicationMapping[link].application === applicationName) {
      // route inside application
      const { routerLink } = linkApplicationMapping[link];
      history.push(routerLink);
    } else {
      // redirect to different application
      const { href } = linkApplicationMapping[link];
      window.location.href = `/${href}`;
    }
  };

  const getLinkStyle = (link: string) => {
    let backgroundColor = '';
    if (hover === link) {
      backgroundColor = 'rgb(51, 176, 183)';
    }
    if (link.toLowerCase() === getLinkBasedOnApplicatioName()) {
      backgroundColor = 'rgb(51, 176, 183)';
    }
    const style = {
      margin: '0 8px',
      padding: '8px 16px',
      fontSize: '18px',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      backgroundColor,
      cursor: 'pointer',
    };
    return style;
  };

  return (
    <div style={{
      display: 'flex',
      marginRight: '16px',
      transition: '50ms ease-in-out',
    }}
    >
      {links.map((link) => (
        <div
          key={link}
          onClick={() => handleClick(link)}
          onPointerOver={() => setHover(link)}
          onPointerOut={() => setHover('')}
          style={getLinkStyle(link)}
        >
          {link}
        </div>
      ))}
    </div>
  );
};

export default SelectApplication;
