import {
  FC,
  CSSProperties,
} from 'react';
import ApplicationLink from './ApplicationLink';

const style: { applicationLinks: CSSProperties } = {
  applicationLinks: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: 16,
  },
};

type Link = {
  label: string,
  routerLink: string,
  href: string,
  application: string,
};

type ApplicationLinksProps = {
  applicationName: string,
  history: any,
};

const ApplicationLinks: FC<ApplicationLinksProps> = ({
  applicationName,
  history,
}: ApplicationLinksProps) => {
  let applicationLinks: Link[] = [];

  if (applicationName === 'poiEditor') {
    if (localStorage.getItem('isPowerUser')) {
      applicationLinks = [
        {
          label: 'Info',
          routerLink: 'buildings',
          href: 'buildings',
          application: 'poiEditor',
        },

        {
          label: 'Map',
          routerLink: 'map-editor',
          href: 'map-editor',
          application: 'poiEditor',
        },
        {
          label: 'Routes',
          routerLink: 'route-editor',
          href: 'route-editor',
          application: 'poiEditor',
        },
        {
          label: 'Positioning',
          routerLink: 'beacon-editor',
          href: 'beacon-editor',
          application: 'poiEditor',
        },
        {
          label: 'Assets',
          routerLink: 'lad-editor',
          href: 'lad-editor',
          application: 'poiEditor',
        },
        {
          label: 'Import',
          routerLink: 'cad-import',
          href: 'cad-import',
          application: 'cadImport',
        },
        {
          label: 'Publish',
          routerLink: 'publish',
          href: 'publish',
          application: 'poiEditor',
        },

      ];
    } else {
      applicationLinks = [
        {
          label: 'Info',
          routerLink: 'buildings',
          href: 'buildings',
          application: 'poiEditor',
        },

        {
          label: 'Map',
          routerLink: 'map-editor',
          href: 'map-editor',
          application: 'poiEditor',
        },
        {
          label: 'Publish',
          routerLink: 'publish',
          href: 'publish',
          application: 'poiEditor',
        },
      ];
    }
  } else if (applicationName === 'liveDashboard') {
    applicationLinks = [
      {
        label: 'Live',
        routerLink: 'live',
        href: 'live',
        application: 'liveDashboard',
      },
    ];
  } else if (applicationName === 'insightsDashboard') {
    applicationLinks = [
      {
        label: 'Beacon Health',
        routerLink: 'beacon-health',
        href: 'beacon-health',
        application: 'insightsDashboard',
      },
    ];
    if (localStorage.getItem('fromOfficeApp')
      && localStorage.getItem('fromOfficeApp') === 'true') {
      if (localStorage.getItem('userLevel')
        && localStorage.getItem('userLevel') === 'ADMIN') {
        applicationLinks.push({
          label: 'Week Planner',
          routerLink: 'week-planner',
          href: 'week-planner',
          application: 'insightsDashboard',
        });
      }
    }
  } else if (applicationName === 'Steerpath') {
    applicationLinks = [{
      label: 'Export Maps',
      routerLink: 'map-export',
      href: 'map-export',
      application: 'Steerpath',
    }];
    if (localStorage.getItem('fromOfficeApp')
      && localStorage.getItem('fromOfficeApp') === 'true') {
      if (localStorage.getItem('userLevel')
        && localStorage.getItem('userLevel') === 'ADMIN') {
        applicationLinks.push({
          label: 'User Management',
          routerLink: 'user-management',
          href: 'user-management',
          application: 'Steerpath',
        });
      }

      if (localStorage.getItem('userLevel')
        && localStorage.getItem('userLevel') === 'SUPER_ADMIN') {
        applicationLinks.push(
          {
            label: 'Organization Management',
            routerLink: 'organization-management',
            href: 'organization-management',
            application: 'Steerpath',
          },
        );
      }
    }
  }

  return (
    <div style={style.applicationLinks}>
      {applicationLinks.map((link) => (
        <ApplicationLink
          key={link.href}
          label={link.label}
          href={link.href}
          routerLink={link.routerLink}
          application={link.application}
          applicationName={applicationName}
          history={history}
        />
      ))}
    </div>
  );
};

export default ApplicationLinks;
