import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
} from 'react';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Switch,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';

import './styles.scss';
import {
  LocalApiKeyDict,
} from '../CognitoLogin/utils';
import SnackbarMessage from '../Dialog/SnackbarMessage';
import { MAPDATA_URL } from '../../config';
import MapLayers from './MapLayers';
import JSONEditor from '../OrganizationManagement/JSONEditor';
import { SnackbarMessageElement } from '../../configs/types/Common';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { mapboxMap: any; }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { steerpathMap: any; }
}
window.mapboxMap = window.mapboxMap || {};
window.steerpathMap = window.steerpathMap || {};

type MapLayerElement = {
  init: () => void;
};

const MapExport: FC = () => {
  const [format, setFormat] = useState('png');
  const [width, setWidth] = useState(160);
  const [height, setHeight] = useState(100);
  const [dpi, setDpi] = useState(300);
  const [scaleControlValue, setScaleControlValue] = useState<number>(10);
  const [isMapScrollShown, setIsMapScrollShown] = useState(true);

  const unit = 'mm';

  const [errorMessage, setErrorMessage] = useState('');

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();
  const mapLayersRef = useRef<MapLayerElement>();
  let defaultMapView = {
    center: {
      lng: 0,
      lat: 0,
    },
    bearing: 0,
    zoom: 0,
    pitch: 0,
  };
  const localMapView = localStorage.getItem('mapView');
  if (localMapView) {
    defaultMapView = JSON.parse(localMapView);
  }
  // eslint-disable-next-line max-len
  const [viewProperties, setViewProperties] = useState(JSON.stringify(defaultMapView, null, 2));

  const handleChangeFormat = (event: ChangeEvent<HTMLInputElement>) => {
    setFormat((event.target as HTMLInputElement).value);
  };

  const toPixels = (length: number) => {
    let conversionFactor = 96;
    if (unit === 'mm') {
      conversionFactor /= 25.4;
    }
    return `${conversionFactor * length}px`;
  };

  const validateDimensions = useCallback((
    inputType: string,
    value: number,
    dpiValue?: number,
  ) => {
    if (!dpiValue) {
      // eslint-disable-next-line no-param-reassign
      dpiValue = dpi;
    }
    if (inputType === 'width' || inputType === 'height') {
      let maxSize;
      if (window.mapboxMap) {
        const canvas = window.mapboxMap.getCanvas();
        const gl = canvas.getContext('experimental-webgl');
        maxSize = gl.getParameter(gl.MAX_RENDERBUFFER_SIZE);
      }
      let val = (unit === 'mm') ? Number(value / 25.4) : value;
      if (val > 0) {
        if (val * dpiValue > maxSize) {
          setErrorMessage('Please check dimensions');
          setSnackbarMessage(`The maximum image dimension is ${maxSize}px, but the ${inputType} entered is ${val * dpi}px.`);
          snackbarRef?.current?.handleOpen();
        } else if (val * window.devicePixelRatio * 96 > maxSize) {
          setErrorMessage('Please check dimensions');
          setSnackbarMessage(`The ${inputType} is unreasonably big`);
          snackbarRef?.current?.handleOpen();
        } else {
          setErrorMessage('');
          if (unit === 'mm') val *= 25.4;
          const mapboxMapEl = window.document.getElementById('mapboxMap');
          if (mapboxMapEl) {
            mapboxMapEl.style[inputType] = toPixels(val);
          }

          window.mapboxMap.resize();
        }
      } else {
        setErrorMessage('Please check dimensions');
      }
    }
  }, [dpi]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputType = event.target.id;
    const value = Number(event.target.value);
    // val * dpiValue => pixelit
    if (inputType === 'width') {
      setWidth(value);
      validateDimensions(inputType, value);
    } else if (inputType === 'height') {
      setHeight(value);
      validateDimensions(inputType, value);
    } else if (inputType === 'dpi') {
      setDpi(value);
      validateDimensions('height', height, value);
      validateDimensions('width', width, value);
    }
  };

  const setZoom = (zoom: number) => {
    const el = window.document.getElementsByClassName('map-export-map') as unknown as HTMLCollectionOf<HTMLElement>;
    const transformOrigin = [0, 0];
    const scale = `scale(${zoom})`;
    const oString = `${transformOrigin[0] * 100}% ${transformOrigin[1] * 100}%`;

    if (el[0]) {
      el[0].style.transform = scale;
      el[0].style.transformOrigin = oString;
    }
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleSliderChange = (_event: any, newValue: number | number[]) => {
    setScaleControlValue(newValue as number);
    setZoom(Number(newValue) / 10);
  };

  useEffect(() => {
    const el = window.document.getElementsByClassName('map-export-map') as unknown as HTMLCollectionOf<HTMLElement>;
    if (el[0]) {
      if (isMapScrollShown === true) {
        el[0].style.overflow = 'scroll';
      } else {
        el[0].style.overflow = 'unset';
      }
    }
  }, [isMapScrollShown]);

  const onJSONEditorChange = (_type: string, value: string) => {
    try {
      if (JSON.parse(value)) {
        const mapView = JSON.parse(value);
        window.mapboxMap.easeTo({
          center: mapView.center,
          pitch: mapView.pitch,
          bearing: mapView.bearing,
          zoom: mapView.zoom,
          duration: 500,
        });
      }
    } catch (error) {
      setSnackbarMessage('Not valid view port');
      snackbarRef?.current?.handleOpen();
    }
    setViewProperties(value);
  };

  const createPrintMap = () => {
    const style = window.mapboxMap.getStyle();
    const zoom = window.mapboxMap.getZoom();
    const center = window.mapboxMap.getCenter();
    const bearing = window.mapboxMap.getBearing();
    const pitch = window.mapboxMap.getPitch();
    // Calculate pixel ratio
    const actualPixelRatio = window.devicePixelRatio;
    Object.defineProperty(window, 'devicePixelRatio', {
      get() { return dpi / 96; },
    });

    // Create map container
    const hidden = document.createElement('div');
    hidden.className = 'hidden-map';
    document.body.appendChild(hidden);
    const container = document.createElement('div');
    container.style.width = toPixels(width);
    container.style.height = toPixels(height);

    hidden.appendChild(container);

    // Render map
    const renderMap = new window.mapboxgl.Map({
      container,
      center,
      zoom,
      style,
      bearing,
      pitch,
      interactive: false,
      preserveDrawingBuffer: true,
      antialias: true,
      fadeDuration: 0,
      customAttribution: '© <a target="_blank" href="https://www.steerpath.com/">Steerpath</a>',
    });

    renderMap.once('idle', () => {
      if (format === 'png') {
        renderMap.getCanvas().toBlob((blob: Blob) => {
          saveAs(blob, 'map.png');
        });
      } else {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: width > height ? 'l' : 'p',
          unit,
          format: [width, height],
          compress: true,
        });

        pdf.addImage(renderMap.getCanvas().toDataURL('image/png'), 'png', 0, 0, width, height, 'map.png', 'FAST');

        const title = window.mapboxMap.getStyle().name;
        const attribution = '(c) © Steerpath © MapTiler © OpenStreetMap contributors';

        pdf.setProperties({
          title,
          creator: 'Print Maps',
          author: attribution,
        });

        pdf.save('map.pdf');
      }

      renderMap.remove();
      if (hidden) {
        hidden?.parentNode?.removeChild(hidden);
      }
      Object.defineProperty(window, 'devicePixelRatio', {
        get() { return actualPixelRatio; },
      });
    });
  };

  useEffect(() => {
    const initializeMap = ((apiKeys: LocalApiKeyDict) => {
      const mapView = JSON.parse(viewProperties);
      const apiKey = apiKeys.PUBLISHED_API_KEY_R;
      const styleUrl = `${MAPDATA_URL}/default.json?access_token=${apiKey}`;

      const mapboxMap = new window.mapboxgl.Map({
        container: 'mapboxMap',
        style: styleUrl,
        center: mapView.center,
        zoom: mapView.zoom,
        bearing: mapView.bearing,
        pitch: mapView.pitch,
        bearingSnap: 0,
        customAttribution: '© <a target="_blank" href="https://www.steerpath.com/">Steerpath</a>',
      });
      mapboxMap.showTileBoundaries = true;

      const steerpathMap = new window.steerpath.SteerpathMap(
        mapboxMap,
        apiKey,
      );

      window.mapboxMap = mapboxMap;
      window.steerpathMap = steerpathMap;

      mapboxMap.addControl(
        new window.steerpath.FloorSwitcherControl(steerpathMap),
        'bottom-right',
      );

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      mapboxMap.on('moveend', (event: any) => {
        if (event.originalEvent && event.originalEvent.type !== 'resize') {
          const newMapView = {
            center: mapboxMap.getCenter(),
            bearing: mapboxMap.getBearing(),
            zoom: mapboxMap.getZoom(),
            pitch: mapboxMap.getPitch(),
          };
          localStorage.setItem('mapView', JSON.stringify(newMapView));
          setViewProperties(JSON.stringify(newMapView, null, 2));
        }
      });

      mapboxMap.once('load', () => {
        mapLayersRef?.current?.init();
      });
    });
    const apiKeys = JSON.parse(localStorage.getItem('API_KEYS') || '{}');
    initializeMap(apiKeys);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateDimensions('width', width);
    validateDimensions('height', height);
  }, [validateDimensions, width, height]);

  const widthPixels = Math.round(((unit === 'mm') ? Number(width / 25.4) : width) * dpi);
  const heightPixels = Math.round(((unit === 'mm') ? Number(height / 25.4) : height) * dpi);
  return (
    <div className="map-export-view">
      <Paper
        elevation={3}
        className="map-export"
      >
        <Typography variant="h4">Map Export</Typography>

        <div className="map-export-container">
          <div className="map-export-sidebar">
            <FormControl component="fieldset">
              <Typography variant="body2">Toggle Map Layers</Typography>
              <MapLayers
                ref={mapLayersRef}
              />
              <Typography variant="body2">Scale Map Container</Typography>
              <Slider
                value={scaleControlValue}
                min={1}
                max={10}
                step={1}
                onChange={handleSliderChange}
                aria-labelledby="continuous-slider"
              />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="body2">Show map scroll</Typography>
                <Switch
                  checked={isMapScrollShown}
                  onChange={() => setIsMapScrollShown(!isMapScrollShown)}
                  color="primary"
                  name="isMapScrollShown"
                />
              </div>
              <Typography variant="body2">Viewport</Typography>
              <JSONEditor
                type="viewProperties"
                value={viewProperties}
                onChange={onJSONEditorChange}
              />
              <Typography variant="body2">Dimensions (mm)</Typography>
              <TextField
                id="width"
                type="number"
                InputProps={{
                  inputProps: {
                    step: 20,
                  },
                }}
                value={width}
                label={`Width ${widthPixels}px`}
                onChange={handleOnChange}
                margin="dense"
              />
              <TextField
                id="height"
                type="number"
                InputProps={{
                  inputProps: {
                    step: 20,
                  },
                }}
                value={height}
                onChange={handleOnChange}
                label={`Height ${heightPixels}px`}
                margin="dense"
              />
              <TextField
                id="dpi"
                type="number"
                InputProps={{
                  inputProps: {
                    step: 20,
                  },
                }}
                value={dpi}
                onChange={handleOnChange}
                label="DPI"
                margin="dense"
              />
              <Typography variant="body2">Format</Typography>
              <RadioGroup
                aria-label="format"
                name="format1"
                value={format}
                onChange={handleChangeFormat}
              >
                <FormControlLabel
                  value="png"
                  control={<Radio />}
                  label="PNG"
                />
                <FormControlLabel
                  value="pdf"
                  control={<Radio />}
                  label="PDF"
                />
              </RadioGroup>

              <p style={{ color: 'red' }}>
                {errorMessage}
              </p>
              <Button
                disabled={errorMessage !== ''}
                variant="contained"
                color="primary"
                onClick={createPrintMap}
              >
                Generate
              </Button>
            </FormControl>
          </div>

          <div className="map-export-map">
            <div id="mapboxMap" />
          </div>
        </div>

        <SnackbarMessage
          message={snackbarMessage}
          ref={snackbarRef}
        />
      </Paper>
      {/* <div style={{ width: "100%", height: "100%" }}>

      </div> */}
    </div>
  );
};

export default MapExport;
