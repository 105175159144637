/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class ConfigView extends React.Component {
  constructor(props) {
    super(props);
    const apiKeys = JSON.parse(localStorage.getItem('API_KEYS'));
    this.state = {
      META_URL: localStorage.getItem('META_URL') || 'default',
      TILE_URL: localStorage.getItem('TILE_URL') || 'default',
      STYLE_URL: localStorage.getItem('STYLE_URL') || 'default',
      ROUTE_URL: localStorage.getItem('ROUTE_URL') || 'default',
      STYLE_TILE_URL: localStorage.getItem('STYLE_TILE_URL') || 'default',
      API_KEYS: {
        IMPORT_API_KEY: apiKeys.IMPORT_API_KEY,
        EDITOR_API_KEY: apiKeys.EDITOR_API_KEY,
        PUBLISHED_API_KEY: apiKeys.PUBLISHED_API_KEY,
      },
      CUSTOM_API_KEY_KEY_INPUT: '',
      CUSTOM_API_KEY_VALUE_INPUT: '',
      CUSTOM_API_KEYS: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStyleUrlChange = this.handleStyleUrlChange.bind(this);
    this.handleRouteUrlChange = this.handleRouteUrlChange.bind(this);
    this.handleChangeTileUrlChange = this.handleChangeTileUrlChange.bind(this);
    this.handleStyleTileUrlChange = this.handleStyleTileUrlChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCustomAPIKeyInput = this.handleCustomAPIKeyInput.bind(this);
    this.handleAddCustomApiKey = this.handleAddCustomApiKey.bind(this);
    this.removeCustomApiKey = this.removeCustomApiKey.bind(this);
  }

  handleInput(event) {
    const { API_KEYS } = this.state;
    API_KEYS[event.target.id] = event.target.value;
    this.setState({
      API_KEYS,
    });
    localStorage.setItem('API_KEYS', JSON.stringify(API_KEYS));
  }

  handleChange(event) {
    this.setState({ META_URL: event.target.value });
    localStorage.setItem('META_URL', event.target.value);
  }

  handleStyleUrlChange(event) {
    this.setState({ STYLE_URL: event.target.value });
    localStorage.setItem('STYLE_URL', event.target.value);
  }

  handleRouteUrlChange(event) {
    this.setState({ ROUTE_URL: event.target.value });
    localStorage.setItem('ROUTE_URL', event.target.value);
  }

  handleStyleTileUrlChange(event) {
    this.setState({ STYLE_TILE_URL: event.target.value });
    localStorage.setItem('STYLE_TILE_URL', event.target.value);
  }

  handleChangeTileUrlChange(event) {
    this.setState({ TILE_URL: event.target.value });
    localStorage.setItem('TILE_URL', event.target.value);
  }

  handleAddCustomApiKey() {
    const {
      CUSTOM_API_KEYS,
      CUSTOM_API_KEY_KEY_INPUT,
      CUSTOM_API_KEY_VALUE_INPUT,
      API_KEYS,
    } = this.state;

    if (this.isValidApiKey()) {
      const newCustomAPIKey = {
        key: CUSTOM_API_KEY_KEY_INPUT,
        value: CUSTOM_API_KEY_VALUE_INPUT,
      };
      CUSTOM_API_KEYS.push(newCustomAPIKey);
      this.setState({
        CUSTOM_API_KEY_KEY_INPUT: '',
        CUSTOM_API_KEY_VALUE_INPUT: '',
        CUSTOM_API_KEYS,
      });

      API_KEYS[newCustomAPIKey.key] = newCustomAPIKey.value;
      localStorage.setItem('API_KEYS', JSON.stringify(API_KEYS));
    }
  }

  handleCustomAPIKeyInput(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  removeCustomApiKey(apiKeyToRemove) {
    const {
      CUSTOM_API_KEYS,
      API_KEYS,
    } = this.state;

    const filteredItems = CUSTOM_API_KEYS.filter(
      (customAPIKey) => customAPIKey.key !== apiKeyToRemove.key,
    );

    this.setState({
      CUSTOM_API_KEYS: filteredItems,
    });

    delete API_KEYS[apiKeyToRemove.key];
    localStorage.setItem('API_KEYS', JSON.stringify(API_KEYS));
  }

  isValidApiKey() {
    const {
      CUSTOM_API_KEY_KEY_INPUT,
      CUSTOM_API_KEY_VALUE_INPUT,
      CUSTOM_API_KEYS,
    } = this.state;

    const newCustomAPIKey = {
      key: CUSTOM_API_KEY_KEY_INPUT,
      value: CUSTOM_API_KEY_VALUE_INPUT,
    };

    const { key } = newCustomAPIKey;
    if (key === 'IMPORT_API_KEY'
      || key === 'EDITOR_API_KEY'
      || key === 'PUBLISHED_API_KEY') {
      console.warn('Cannot override existin ones');
      return false;
    }
    const customAPIKeys = CUSTOM_API_KEYS;
    for (let i = 0; i < customAPIKeys.length; i += 1) {
      const eachCustomApiKey = customAPIKeys[i];
      if (newCustomAPIKey.key === eachCustomApiKey.key) {
        console.warn('already have custom api key');
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      CUSTOM_API_KEYS,
      META_URL,
      STYLE_TILE_URL,
      TILE_URL,
      STYLE_URL,
      ROUTE_URL,
      API_KEYS,
      CUSTOM_API_KEY_KEY_INPUT,
      CUSTOM_API_KEY_VALUE_INPUT,
    } = this.state;

    let customAPIKeys = <div />;
    if (CUSTOM_API_KEYS.length) {
      customAPIKeys = CUSTOM_API_KEYS.map((customAPIKey) => (
        <div
          key={customAPIKey.value}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p>
            <b>Key: </b>
            {' '}
            {customAPIKey.key}
            {' '}
            <b> Value: </b>
            {customAPIKey.value}
          </p>
          <button
            value={customAPIKey}
            onClick={() => this.removeCustomApiKey(customAPIKey)}
            type="button"
          >
            Remove
          </button>
        </div>
      ));
    }

    return (
      <div>
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          Settings
        </Typography>
        <label htmlFor="meta">
          Meta Service
          <select
            value={META_URL}
            onChange={this.handleChange}
          >
            <option
              disabled
              value="default"
            >
              {' '}
              -- select an option --
            </option>
            <option value="https://meta2.eu.steerpath.net/meta/v2">https://meta2.eu.steerpath.net/meta/v2</option>
            <option value="https://meta2-staging.steerpath.net/meta/v2">https://meta2-staging.steerpath.net/meta/v2</option>
            <option value="https://meta2-staging.sg.steerpath.net/meta">https://meta2-staging.sg.steerpath.net/meta</option>
            <option value="https://meta.sg2.steerpath.net/meta">https://meta.sg2.steerpath.net/meta</option>
          </select>
        </label>
        <br />

        <label htmlFor="style">
          Style
          <select
            value={STYLE_URL}
            onChange={this.handleStyleUrlChange}
          >
            <option
              disabled
              value="default"
            >
              {' '}
              -- select an option --
            </option>
            <option value="https://mapdata.eu.steerpath.net/style/default.json?access_token=">https://mapdata.eu.steerpath.net/style/default.json?access_token=</option>
            <option value="https://mapdata-staging.eu.steerpath.net/style/default.json?access_token=">https://mapdata-staging.eu.steerpath.net/style/default.json?access_token=</option>
            <option value="https://mapdata-staging.sg.steerpath.net/style/default.json?access_token=">https://mapdata-staging.sg.steerpath.net/style/default.json?access_token=</option>
            <option value="https://mapdata.sg2.steerpath.com/style/default.json?access_token=">https://mapdata.sg2.steerpath.com/style/default.json?access_token=</option>

          </select>
        </label>
        <br />

        <label htmlFor="tiles">
          Tiles
          <select
            value={STYLE_TILE_URL}
            onChange={this.handleStyleTileUrlChange}
          >
            <option
              disabled
              value="default"
            >
              {' '}
              -- select an option --
            </option>
            <option value="https://mapdata.eu.steerpath.net/vector-tiles">https://mapdata.eu.steerpath.net/vector-tiles</option>
            <option value="https://mapdata-staging.eu.steerpath.net/vector-tiles">https://mapdata-staging.eu.steerpath.net/vector-tiles</option>
            <option value="https://mapdata-staging.sg.steerpath.net/vector-tiles">https://mapdata-staging.sg.steerpath.net/vector-tiles</option>
            <option value="https://mapdata.sg2.steerpath.com/vector-tiles">https://mapdata.sg2.steerpath.com/vector-tiles</option>
          </select>
        </label>
        <br />

        <label htmlFor="route">
          Route
          <select
            value={ROUTE_URL}
            onChange={this.handleRouteUrlChange}
          >
            <option
              disabled
              value="default"
            >
              {' '}
              -- select an option --
            </option>
            <option value="https://routes.eu.steerpath.net/all_routes.json">https://routes.eu.steerpath.net/all_routes.json</option>
            <option value="https://routes-staging.eu.steerpath.net/all_routes.json">https://routes-staging.eu.steerpath.net/all_routes.json</option>
            <option value="https://routes-staging.sg.steerpath.net/all_routes.json">https://routes-staging.sg.steerpath.net/all_routes.json</option>
            <option value="https://routes.sg2.steerpath.com/all_routes.json">https://routes.sg2.steerpath.com/all_routes.json</option>
          </select>
        </label>
        <br />

        <label htmlFor="tile">
          Tile Generation
          <select
            value={TILE_URL}
            onChange={this.handleChangeTileUrlChange}
          >
            <option
              disabled
              value="default"
            >
              {' '}
              -- select an option --
            </option>
            <option value="https://lambda.eu.steerpath.net/mapupload">https://lambda.eu.steerpath.net/mapupload</option>
            <option value="https://lambda.eu.steerpath.net/mapupload-staging">https://lambda.eu.steerpath.net/mapupload-staging</option>
            <option value="https://lambda.sg2.steerpath.com/mapupload">https://lambda.sg2.steerpath.com/mapupload</option>
          </select>
        </label>
        <br />

        Production
        <input
          id="PUBLISHED_API_KEY"
          onChange={this.handleInput}
          value={API_KEYS.PUBLISHED_API_KEY}
        />
        <br />
        Editor
        <input
          id="EDITOR_API_KEY"
          onChange={this.handleInput}
          value={API_KEYS.EDITOR_API_KEY}
        />
        <br />
        Import
        <input
          id="IMPORT_API_KEY"
          onChange={this.handleInput}
          value={API_KEYS.IMPORT_API_KEY}
        />
        <br />
        Custom API Key
        <br />
        <input
          id="CUSTOM_API_KEY_KEY_INPUT"
          onChange={this.handleCustomAPIKeyInput}
          value={CUSTOM_API_KEY_KEY_INPUT}
        />
        <input
          id="CUSTOM_API_KEY_VALUE_INPUT"
          onChange={this.handleCustomAPIKeyInput}
          value={CUSTOM_API_KEY_VALUE_INPUT}
        />
        <button
          type="button"
          onClick={this.handleAddCustomApiKey}
        >
          Add
        </button>
        {customAPIKeys}
      </div>
    );
  }
}
