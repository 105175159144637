const isProd = process.env.REACT_APP_ENV === 'production';

export const VERIFY_EMAIL_URL = isProd
  ? 'https://verify-email.eu.steerpath.net'
  : 'https://verify-email-staging.eu.steerpath.net';

export const MIGRATION_ENDPOINT = isProd
  ? 'https://migrate.eu.steerpath.net/migrate'
  : 'https://migrate-staging.eu.steerpath.net/migrate';

export const MAPDATA_URL = isProd
  ? 'https://mapdata.eu.steerpath.net/style'
  : 'https://mapdata-staging.eu.steerpath.net/style';

const S3_BUCKET = isProd
  ? 'predefined-profile-images-officeapp-officeapp'
  : 'predefined-profile-images-officeapp-dev';

const REGION = isProd
  ? 'eu-west-1'
  : 'eu-central-1';

export const O365_PROVIDER_URL = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/provider-mapping.json`;
