import { FC } from 'react';
import { SteerpathToolbar } from '@steerpath-dashboard/toolbar';
import history from './history';

import { useAuth } from './CognitoLogin/utils';

const MasterHeader: FC = () => {
  const { auth } = useAuth();

  const selectMenuClick = () => {};

  if (!auth) return <div />;

  return (
    <SteerpathToolbar
      history={history}
      applicationName="Steerpath"
      selectMenuItems={[]}
      defaultSelectedItem=""
      selectMenuClick={selectMenuClick}
    />
  );
};

export default MasterHeader;
