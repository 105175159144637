import React, { forwardRef, useImperativeHandle } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuSelectItem } from '../../configs/types/Common';

interface MenuSelectProps {
  type: string
  items: Array<MenuSelectItem>
  onMenuItemSelect: (type: string, item: MenuSelectItem) => void;
}
const MenuSelect = forwardRef((props: MenuSelectProps, ref) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item: MenuSelectItem) => {
    props.onMenuItemSelect(props.type, item);
    handleClose();
  };

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
      },
    }),
  );

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.items.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => handleSelect(item)}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

MenuSelect.displayName = 'MenuSelect';

export default MenuSelect;
