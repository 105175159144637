import {
  FC,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  applicationLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    margin: '0 16px',
    padding: '8px 24px 8px',
    borderBottom: '2px solid rgba(0,0,0,0)',
    fontSize: '0.8125rem', // Double check this
    textTransform: 'uppercase',
    transition: '50ms ease-in-out',
    '&:hover': {
      borderBottom: '2px solid #fff',
      cursor: 'pointer',
    },
  },
});

type ApplicationLinkProps = {
  application: string,
  applicationName: string,
  href: string,
  label: string,
  routerLink: string,
  history: any,
};

const ApplicationLink: FC<ApplicationLinkProps> = ({
  application,
  applicationName,
  href,
  label,
  routerLink,
  history,
}: ApplicationLinkProps) => {
  const classes = useStyles();

  const handleRoute = () => {
    if (application === applicationName) {
      history.push(routerLink);
    } else {
      window.location.href = `/${href}`;
    }
  };

  return (
    <div
      className={classes.applicationLink}
      onClick={handleRoute}
    >
      {label}
    </div>
  );
};

export default ApplicationLink;
