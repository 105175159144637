import {
  FC,
  CSSProperties,
  useState,
  ChangeEvent,
} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { VERIFY_EMAIL_URL } from '../../config';

import { cognito } from '../CognitoLogin/Cognito';

const style: {
  changeEmail: CSSProperties,
  buttons: CSSProperties,
  emailTextField: CSSProperties,
  errorMessage: CSSProperties,
  successMessage: CSSProperties,
} = {
  changeEmail: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '360px',
    marginTop: '48px',
    marginBottom: '48px',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  emailTextField: {
    marginBottom: '16px',
  },
  errorMessage: {
    marginBottom: '16px',
    color: 'red',
  },
  successMessage: {
    marginBottom: '16px',
    color: 'green',
  },
};

const EMAIL_CHANGE_ERROR_MESSAGE = 'Can\'t change the email address. Please contact support@steerpath.com';

const ChangeEmail: FC = () => {
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleNewEmailChange = (e: ChangeEvent<{ value: unknown }>) => {
    setNewEmail(e.target.value as string);
  };

  const handleConfirmNewEmailChange = (e: ChangeEvent<{ value: unknown }>) => {
    setConfirmNewEmail(e.target.value as string);
  };

  const verifyEmail = (email: string) => fetch(VERIFY_EMAIL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });

  const changeEmail = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      await cognito.changeEmail(newEmail);
      await verifyEmail(newEmail);
    } catch (err) {
      console.error(err);
      setErrorMessage(EMAIL_CHANGE_ERROR_MESSAGE);
    }
  };

  const emailsAreValid = (email: string, confirmEmail: string) => {
    // FIXME: Actually validate emails
    if (!email.length || !confirmEmail.length) {
      return false;
    }
    if (email !== confirmEmail) {
      return false;
    }
    if (!email.includes('@')) {
      return false;
    }
    if (!email.includes('.')) {
      return false;
    }

    return true;
  };

  const emailsMatch = emailsAreValid(newEmail, confirmNewEmail);

  return (
    <div style={style.changeEmail}>
      <Typography
        variant="subtitle1"
        gutterBottom
      >
        Change email address
      </Typography>
      <TextField
        id="newEmail"
        label="New email address"
        onChange={handleNewEmailChange}
        style={style.emailTextField}
      />

      <TextField
        id="confirmNewEmail"
        label="Confirm new email address"
        onChange={handleConfirmNewEmailChange}
        style={style.emailTextField}
      />

      {errorMessage
        && (
          <div style={style.errorMessage}>
            {errorMessage}
          </div>
        )}

      {successMessage
        && (
          <div style={style.successMessage}>
            {errorMessage}
          </div>
        )}

      <div style={style.buttons}>
        <Button>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!emailsMatch}
          onClick={changeEmail}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default ChangeEmail;
