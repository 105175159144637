import {
  CSSProperties,
  ReactChild,
  ReactChildren,
  FC,
} from 'react';

const style: { migrateView: CSSProperties } = {
  migrateView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '64px',
  },
};

type MigrateViewProps = {
  children: ReactChild | ReactChildren,
};

const MigrateView: FC<MigrateViewProps> = ({ children }: MigrateViewProps) => (
  <div style={style.migrateView}>
    {children}
  </div>
);

export default MigrateView;
