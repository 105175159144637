import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import gql from 'graphql-tag';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';

import {
  CreateEmailPatternInput,
  CreateEmailPatternMutation,
  DeleteEmailPatternInput,
  DeleteEmailPatternMutation,
  ReadAllEmailPatternsByOrgQuery,
} from '../types/EmailPattern';
import { UserLevel } from '../types/User';

export const validateEmailPattern = (
  domain: string,
): boolean => /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(domain);

export const getEmailAddressPatternByOrganization = async (
  orgId: string,
): Promise<GraphQLResult<ReadAllEmailPatternsByOrgQuery>> => {
  const res = await API.graphql(graphqlOperation(gql`
    query readAllEmailPatternsByOrg {
      readAllEmailPatternsByOrg(orgId: "${orgId}")  {
        items {
          createdAt,
          orgId,
          updatedAt,
          defaultUserLevel,
          addressPattern
        }
      }
    }
  `)) as GraphQLResult<ReadAllEmailPatternsByOrgQuery>;

  return res;
};

export const createEmailPattern = async (
  emailAddressLookupInput: CreateEmailPatternInput,
): Promise<GraphQLResult<CreateEmailPatternMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation createEmailPattern {
      createEmailPattern(input: {
        addressPattern: "${emailAddressLookupInput.addressPattern}",
        defaultUserLevel: ${(emailAddressLookupInput.defaultUserLevel as UserLevel)},
        orgId: "${emailAddressLookupInput.orgId}"
      }) {
        addressPattern
        defaultUserLevel
        orgId
      }
    }
    `)) as GraphQLResult<CreateEmailPatternMutation>;
  return res;
};

export const deleteEmailPattern = async (
  emailAddressLookupInput: DeleteEmailPatternInput,
): Promise<GraphQLResult<DeleteEmailPatternMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation deleteEmailPattern {
      deleteEmailPattern(input: {addressPattern: "${emailAddressLookupInput.addressPattern}"}) {
        orgId
      }
    }
    `)) as GraphQLResult<DeleteEmailPatternMutation>;

  return res;
};
