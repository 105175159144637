import { FC } from 'react';

import Typography from '@material-ui/core/Typography';

import ApiKey from './ApiKey';

type KeyItem = {
  apiKey: string,
  accessLevel: string,
};

type KeyItemProps = {
  title: string,
  instruction?: string,
  keyList: KeyItem[],
};

const KeyContainer: FC<KeyItemProps> = ({ title, keyList, instruction }: KeyItemProps) => (
  <div style={{ marginBottom: '24px' }}>
    <Typography
      variant="h6"
      gutterBottom
    >
      {title}
    </Typography>
    {instruction
        && (
          <Typography>
            {instruction}
          </Typography>
        )}
    {
      keyList.filter(({ apiKey }) => apiKey && apiKey.length > 0)
        .map(({ apiKey, accessLevel }) => (
          <ApiKey
            apiKey={apiKey}
            accessLevel={accessLevel}
            key={apiKey}
          />
        ))
    }
  </div>
);

KeyContainer.defaultProps = {
  instruction: '',
};

export default KeyContainer;

export type {
  KeyItem,
};
