import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/snippets/json';
import 'brace/ext/language_tools';
import 'brace/ext/searchbox';
import 'brace/theme/dreamweaver';
import 'brace/theme/eclipse';
import JSONLint from 'json-lint';

interface JSONEditorProps {
  type: string,
  value: string,
  onChange: (type: string, value: string) => void;
  height?: string;
}

type Annotation = {
  row: number,
  type: string,
  text: string
};

const JSONEditor: React.FC<JSONEditorProps> = (props: JSONEditorProps) => {
  const handleOnChange = (value: string) => {
    props.onChange(props.type, value);
  };
  let style = {
    outline: '2px solid #c0c0c0',
    maxWidth: '640px',
  };
  let annotations:Array<Annotation> = [];

  const lint = JSONLint(props.value);
  if (lint.error) {
    annotations = [{
      row: lint.line - 1,
      type: 'error',
      text: lint.error,
    }];
    style = {
      outline: '2px solid #f50057',
      maxWidth: '640px',
    };
  }

  return (
    <div style={style}>
      <AceEditor
        value={props.value}
        name={props.type}
        mode="json"
        theme="eclipse"
        width="100%"
        style={{ maxWidth: '640px' }}
        height={props.height}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        onChange={handleOnChange}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
        editorProps={{ $blockScrolling: true }}
        annotations={annotations}
      />
    </div>
  );
};

JSONEditor.displayName = 'JSONEditor';
JSONEditor.defaultProps = {
  height: '200px',
};

export default JSONEditor;
