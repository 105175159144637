const isProd = process.env.REACT_APP_ENV === 'production';

export const defaultFeedbackConfig = {
  version: '1.0',
  backend: isProd
    ? 'https://email-gateway.eu.steerpath.net/sendEmail'
    : 'https://email-gateway.steerpath.dev/sendEmail',
  Source: 'support@steerpath.com',
  ToAddresses: ['support@steerpath.com'],
};
