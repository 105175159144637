import {
  FC,
  useState,
  ChangeEvent,
} from 'react';

import jwtDecode from 'jwt-decode';
import JSONPretty from 'react-json-pretty';

import TextField from '@material-ui/core/TextField';

const style = {
  apiKeyForm: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  red: {
    color: 'red',
  },
};

type APIKeyFormProps = {
  tokenChanged: (token: string | null, decodedToken: { sub: string } | null) => void,
};

const APIKeyForm: FC<APIKeyFormProps> = ({ tokenChanged }: APIKeyFormProps) => {
  const [token, setToken] = useState('');
  const [decodedToken, setDecodedToken] = useState<{ sub: string } | null>(null);

  const decodeToken = (tokenValue: string) => {
    try {
      const decodedTokenValue = jwtDecode(tokenValue) as { sub: string };
      if (decodedTokenValue) {
        tokenChanged(tokenValue, decodedTokenValue);
        setDecodedToken(decodedTokenValue);
      } else {
        setDecodedToken(null);
        tokenChanged(null, null);
      }
    } catch (err) {
      console.error(err);
      setDecodedToken(null);
      tokenChanged(null, null);
    }
  };

  const handleTextChange = (e: ChangeEvent<{ value: unknown }>) => {
    const tokenValue = e.target.value as string;
    setToken(tokenValue);
    decodeToken(tokenValue);
  };

  return (
    <div style={style.apiKeyForm}>
      <TextField
        autoFocus
        fullWidth
        id="token"
        label="Old customer's write API key"
        value={token}
        onChange={handleTextChange}
        margin="normal"
      />

      {token && !decodedToken
        && (
          <span style={style.red}>
            Not a valid token.
          </span>
        )}

      {decodedToken
         && (
           <JSONPretty
             id="json-pretty"
             data={decodedToken}
           />
         )}
    </div>
  );
};

export default APIKeyForm;
