/* eslint-disable max-len */
import React, { useRef, useMemo, useCallback } from 'react';
import './styles.scss';
import {
  Button,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import MenuSelect from './MenuSelect';
import { createEmailPattern } from '../../configs/graphql/emailPattern';
import {
  MenuElement,
  MenuSelectItem,
  SnackbarMessageElement,
} from '../../configs/types/Common';
import { UserLevel } from '../../configs/types/User';
import { CreateEmailPatternInput } from '../../configs/types/EmailPattern';
import SnackbarMessage from '../Dialog/SnackbarMessage';
import { IdentityProvider } from '../../configs/types/Org';

const getPrefix = (mode: string, idpFederationProvider?: IdentityProvider, idpFederationTenantName?: string) => {
  // if mode is add_new_email_password then prefix is always emailpw regardless login provider
  if (mode === 'add_new_email_password') return 'emailpw,';

  // for GOOGLE, prefix is simple -> it's just google,
  if (mode !== 'add_new_email_password' && idpFederationProvider === 'GOOGLE') return 'google,';

  // for AZURE and SAML prefix is tenantName, (if tenantName is not defined then it's emailpw,)
  if (mode !== 'add_new_email_password' && (idpFederationProvider === 'AZURE' || idpFederationProvider === 'SAML') && idpFederationTenantName) return `${idpFederationTenantName},`;

  return 'emailpw,';
};

const validateEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

interface AddNewUserProps {
  mode: MenuSelectItem['value']
  orgId: string
  idpFederationProvider: IdentityProvider | undefined;
  idpFederationTenantName: string | undefined;
  onCancelAddNewCard: () => void
  updateInvitees: () => void
}

const AddNewCard: React.FC<AddNewUserProps> = ({
  mode,
  orgId,
  idpFederationProvider,
  idpFederationTenantName,
  onCancelAddNewCard,
  updateInvitees,
}: AddNewUserProps) => {
  const [email, setEmail] = React.useState(getPrefix(mode, idpFederationProvider, idpFederationTenantName));
  const [userLevel, setUserLevel] = React.useState('NORMAL');
  const userLevelMenuRef = useRef<MenuElement>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();
  const userLevelItems = [
    {
      text: 'Normal',
      value: 'NORMAL',
    },
    {
      text: 'Power user',
      value: 'POWER_USER',
    },
    {
      text: 'Admin',
      value: 'ADMIN',
    },
  ];

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleOpenUserLevelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    userLevelMenuRef?.current?.handleOpen(event);
  };

  const onUserLevelMenuItemSelect = (_type: string, item: MenuSelectItem) => {
    setUserLevel(item.value); // TODO continue from here with the select
  };

  const handleCancel = () => {
    onCancelAddNewCard();
  };

  const showSnackbarMessage = useCallback((message: string) => {
    setSnackbarMessage(message);
    snackbarRef?.current?.handleOpen();
  }, []);

  const asyncFunction = useCallback(async (addressPattern: string) => {
    const emailAddressLookupInput: CreateEmailPatternInput = {
      addressPattern,
      defaultUserLevel: (userLevel as UserLevel),
      orgId: orgId.toString(),
    };
    const { data, errors } = await createEmailPattern(emailAddressLookupInput);
    if (data?.createEmailPattern) {
      updateInvitees();
    } else if (errors && errors?.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach((error: Error) => {
        errorMessages.push(error.message);
      });
      showSnackbarMessage(`Could not load Invitees ${errorMessages}`);
    }
  }, [orgId, showSnackbarMessage, updateInvitees, userLevel]);

  const handleSave = useCallback(async () => {
    // when adding new domain no need to validate emails
    if (mode === 'add_new_sso_domain') {
      try {
        await asyncFunction(email);
      } catch (error) {
        showSnackbarMessage('Something went wrong. Are you sure you are adding correct domain? For example @steerpath.com');
      }
    } else {
      try {
        /** if there are more than 2 comma separated values in the email text field
         * try to upload multiple invitees. Validate the emails first!
         */
        const emailList = email.split(',').map((emailAddress) => emailAddress.trim());
        // multiple
        if (emailList.length > 2) {
          const invalidEmails: string[] = [];
          emailList.forEach((emailAddress, index) => {
            if (index !== 0 && !validateEmail(emailAddress)) invalidEmails.push(emailAddress);
          });
          // if any of the emails is not valid, then show error
          if (invalidEmails.length > 0) {
            showSnackbarMessage(`Following invitees are not valid: ${invalidEmails}`);
            return;
          }
          // upload!
          await Promise.all(emailList.map(async (addressPattern, index) => {
            if (index === 0) return;
            await asyncFunction(`${emailList[0]},${addressPattern}`);
          }));
        } else if (emailList.length === 2) {
          if (!validateEmail(emailList[1])) {
            showSnackbarMessage(`Not a valid Email: ${emailList[1]}`);
            return;
          }
          // upload!
          await asyncFunction(email);
        } else {
          showSnackbarMessage('Somethin went wrong. Have you added the login provider prefix? For example emailpw,');
        }
      } catch (error) {
        showSnackbarMessage('Something went wrong. Have you added the login provider prefix? For example emailpw,');
      }
    }
  }, [asyncFunction, email, mode, showSnackbarMessage]);

  const headerTitle = useMemo(() => {
    if (mode === 'add_new_sso_email') return 'New SSO user';
    if (mode === 'add_new_sso_domain') return 'New domain';
    if (mode === 'add_new_email_password') return 'New user with email and password';
    return '';
  }, [mode]);

  const bodyText = useMemo(() => {
    if (mode === 'add_new_sso_email') return 'Add the email of the user you want to invite. If you want to invite multiple users, separate the emails with a comma.';
    if (mode === 'add_new_sso_domain') return 'Add your company domain to invite all users with that domain';
    if (mode === 'add_new_email_password') return 'Add the email of the user you want to invite. If you want to invite multiple users, separate the emails with a comma.';
    return '';
  }, [mode]);

  const textFieldPlaceholder = useMemo(() => {
    if (mode === 'add_new_sso_email') return 'john.doe@company.com';
    if (mode === 'add_new_sso_domain') return '@company.com';
    if (mode === 'add_new_email_password') return 'john.doe@company.com';
    return '';
  }, [mode]);

  const textFieldLabel = useMemo(() => {
    if (mode === 'add_new_sso_email') return 'Email';
    if (mode === 'add_new_sso_domain') return 'Domain';
    if (mode === 'add_new_email_password') return 'Email';
    return '';
  }, [mode]);
  return (
    <div className="user-management-add-new-user">
      <Paper
        elevation={10}
        className="user-management-add-new-user-paper"
      >

        <div className="user-management-add-new-user-header">
          <Typography variant="h6">
            {headerTitle}
          </Typography>
          <Typography variant="body1">
            {bodyText}
          </Typography>
        </div>

        <div className="user-management-add-new-user-content">
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <TextField
              style={{ width: '72%' }}
              label={textFieldLabel}
              value={email}
              placeholder={textFieldPlaceholder}
              onChange={handleSetEmail}
              multiline
            />

            <div style={{ marginLeft: 8 }}>
              <Button
                variant="contained"
                onClick={handleOpenUserLevelMenu}
              >
                {userLevel}
              </Button>
              <MenuSelect
                type="userLevel"
                ref={userLevelMenuRef}
                items={userLevelItems}
                onMenuItemSelect={onUserLevelMenuItemSelect}
              />
            </div>
          </div>

          <div style={{ marginLeft: '32px', alignSelf: 'flex-end' }}>
            <Button
              onClick={handleCancel}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>

      </Paper>
      <SnackbarMessage
        message={snackbarMessage}
        ref={snackbarRef}
      />
    </div>
  );
};

export default AddNewCard;
