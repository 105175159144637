const env = process.env.REACT_APP_ENV || 'development';
const isProd = (env === 'production');

const {
  host,
  protocol,
} = window.location;

const redirectUrl = `${protocol}//${host}/company-login`;

const orgAwsConfig = {
  Auth: {
    identityPoolId: isProd
      ? 'eu-west-1:2fe2a7a9-23da-4f8f-975a-a51d9d720cbe'
      : 'eu-central-1:f7f1bc54-67be-4e81-886f-9170eb2ffe3f',
    region: isProd
      ? 'eu-west-1'
      : 'eu-central-1',
    userPoolId: isProd
      ? 'eu-west-1_Ce3eEHADk'
      : 'eu-central-1_nHGONIa38',
    userPoolWebClientId: isProd
      ? '4o4balentci2kg6vkpnqv2uijv'
      : '31f27183gkfs7g85v7j00ceteq',
    oauth: {
      domain: isProd
        ? 'login.steerpath.app'
        : 'steerpathofficeapp-dev.auth.eu-central-1.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    },
  },
  aws_appsync_graphqlEndpoint: isProd
    ? 'https://dashboard-api.steerpath.net/graphql'
  // 'https://st52ymlzirfwvavw73dolnnh3a.appsync-api.eu-west-1.amazonaws.com/graphql'
    : 'https://dashboard-api.steerpath.dev/graphql',
  // : 'https://262sul3idjfvtorp5ovbkx3ubq.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: isProd
    ? 'eu-west-1'
    : 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

const dashboardAwsConfig = {
  Auth: {
    userPoolId: isProd
      ? 'eu-west-1_QCZkyO7Gh'
      : 'eu-west-1_cmzrK6Uwm',
    userPoolWebClientId: isProd
      ? '17nbl8203rhplujkohjcmnd26s'
      : '3kdnqbh911jbn76mkf8tbvkg1o',
  },
};

export {
  orgAwsConfig,
  dashboardAwsConfig,
};
