/* eslint-disable @typescript-eslint/explicit-module-boundary-types, react/prop-types */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { cognito } from './Cognito';
import LoginView from './LoginView';

const style = {
  view: {
    width: '100%',
  },
  button: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  confirmView: {
  },
  errorMessage: {
    color: 'red',
    marginTop: '16px',
    margingBottom: '16px',
  },
  successMessage: {
    color: 'green',
    marginTop: '16px',
    margingBottom: '16px',
  },
};

const MIN_PASSWORD_LENGTH = 3;

const ErrorMessage = ({ children }) => (
  <div style={style.errorMessage}>
    {children}
  </div>
);

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      newPassword: '',
      confirmNewPassword: '',
      confirmationCode: '',
      confirmationCodeSent: false,
      passwordChanged: false,
      errorMessage: '',
      settingPassword: false,
      resettingPassword: false,
    };

    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.resetErrorMessage = this.resetErrorMessage.bind(this);
    this.handleForgotClick = this.handleForgotClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePasswords = this.validatePasswords.bind(this);
    this.validateEmail = this.validateEmail.bind(this);

    this.renderConfirmView = this.renderConfirmView.bind(this);
  }

  componentDidMount() {
    cognito.switchToDashboardAppPool();
  }

  handleChange(name) {
    return (event) => {
      this.setState({
        [name]: event.target.value,
      });
    };
  }

  async handleForgotClick() {
    const { email } = this.state;

    this.resetErrorMessage();
    const emailValid = this.validateEmail();
    if (!emailValid) {
      this.setErrorMessage('Incorrect email');
      return;
    }

    this.setState({ resettingPassword: true });

    try {
      await cognito.resetPassword(email);
      this.setState({
        confirmationCodeSent: true,
        resettingPassword: false,
      });
    } catch (err) {
      console.error(err);
      this.setErrorMessage('Unable to reset password. Please contact support@steerpath.com');
      this.setState({ resettingPassword: false });
    }
  }

  async handleConfirmClick() {
    this.resetErrorMessage();

    const {
      confirmationCode,
      newPassword,
      confirmNewPassword,
      email,
    } = this.state;

    if (!confirmationCode) {
      this.setErrorMessage('Please enter the confirmation code');
      return;
    }

    const passwordsValid = this.validatePasswords(newPassword, confirmNewPassword);
    if (!passwordsValid) {
      console.error('Passwords are not valid');
      return;
    }

    this.setState({ settingPassword: true });

    try {
      await cognito.confirmPassword(email, confirmationCode, newPassword);
      this.setState({
        passwordChanged: true,
        settingPassword: false,
      });
    } catch (err) {
      console.error(err);
      this.setErrorMessage(err.message);
      this.setState({ settingPassword: false });
    }
  }

  setErrorMessage(errorMessage) {
    this.setState({
      errorMessage,
    });
  }

  resetErrorMessage() {
    this.setState({
      errorMessage: '',
    });
  }

  validateEmail() {
    const { email } = this.state;
    if (!email.includes('@')) {
      return false;
    }
    if (!email.includes('.')) {
      return false;
    }

    return true;
  }

  validatePasswords(newPassword, confirmNewPassword) {
    if (newPassword !== confirmNewPassword) {
      this.setErrorMessage('Passwords do not match');
      return false;
    }
    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      this.setErrorMessage(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`);
      return false;
    }
    return true;
  }

  renderForgotView() {
    const {
      email,
      errorMessage,
      resettingPassword,
    } = this.state;
    return (
      <div style={style.view}>
        <Typography variant="h5">
          Reset Password
        </Typography>
        {/* <form noValidate autoComplete="off"> */}

        <TextField
          fullWidth
          id="email"
          label="Email"
          value={email}
          onChange={this.handleChange('email')}
          margin="normal"
        />
        <br />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Button
          style={style.button}
          variant="contained"
          color="primary"
          onClick={this.handleForgotClick}
        >
          {resettingPassword ? 'Resetting password...' : 'Reset My Password'}
        </Button>
        <br />
        {/* Don't have an account yet? Sign Up for free! <br/><br/>
        Forgot password? */}
        Already have an account?&nbsp;
        <Link to="/login">
          Log in.
        </Link>
      </div>
    );
  }

  renderConfirmView() {
    const {
      email,
      confirmationCode,
      newPassword,
      confirmNewPassword,
      errorMessage,
      settingPassword,
    } = this.state;

    return (
      <div style={style.view}>
        <Typography variant="h5">
          Set New Password
        </Typography>
        <br />
        Please check your email
        {' '}
        <strong>
          (
          {email}
          )
        </strong>
        {' '}
        for the verification code.
        <br />

        <TextField
          fullWidth
          id="confirmationCode"
          label="Verification Code"
          value={confirmationCode}
          onChange={this.handleChange('confirmationCode')}
          margin="normal"
        />

        <TextField
          fullWidth
          id="newPassword"
          label="New Password"
          type="password"
          value={newPassword}
          onChange={this.handleChange('newPassword')}
          margin="normal"
        />

        <TextField
          fullWidth
          id="confirmNewPassword"
          label="Confirm New Password"
          type="password"
          value={confirmNewPassword}
          onChange={this.handleChange('confirmNewPassword')}
          margin="normal"
        />

        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Button
          style={style.button}
          variant="contained"
          color="primary"
          onClick={this.handleConfirmClick}
        >
          {settingPassword ? 'Setting New Password...' : 'Set New Password'}

        </Button>
      </div>
    );
  }

  render() {
    const renderChangedView = () => {
      const loginURL = '/login';

      return (
        <div style={style.view}>
          <Typography variant="h5">
            Password Changed
          </Typography>
          <br />
          Your password has been successfully changed. Please
          {' '}
          <a href={loginURL}>login</a>
          .
        </div>
      );
    };

    const {
      confirmationCodeSent,
      passwordChanged,
    } = this.state;

    const showForgotView = !passwordChanged && !confirmationCodeSent;
    const showConfirmView = !passwordChanged && confirmationCodeSent;
    const showChangedView = passwordChanged;

    return (
      <LoginView>
        {showForgotView && this.renderForgotView()}
        {showConfirmView && this.renderConfirmView() }
        {showChangedView && renderChangedView()}
      </LoginView>
    );
  }
}

export default ForgotPassword;
