import { FC } from 'react';

import Typography from '@material-ui/core/Typography';

import ChangeEmail from './ChangeEmail';

type User = {
  sub: string,
  email: string,
  email_verified: string,
};

type BasicInformationProps = {
  currentUser: User | null,
  isPowerUser: boolean,
};

const BasicInformation: FC<BasicInformationProps> = (
  { currentUser, isPowerUser }: BasicInformationProps,
) => (
  <div style={{ marginBottom: '24px' }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
    }}
    >
      {currentUser && currentUser.email}
      {' '}
      <br />
      <Typography>
        {currentUser && currentUser.email_verified
          ? ' (verified)'
          : ' (not verified)'}
      </Typography>
    </div>

    <Typography>
      {currentUser && currentUser.sub}
      {' '}
      <br />
    </Typography>

    {
      isPowerUser
      && (
        <ChangeEmail />
      )
    }
  </div>
);

export default BasicInformation;
