import {
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useRef, useEffect } from 'react'; import './styles.scss';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Paper from '@material-ui/core/Paper';
import {
  DialogElement,
  MenuElement,
  MenuSelectItem,
} from '../../configs/types/Common';
import history from '../history';
import MenuSelect from './MenuSelect';
import {
  deleteUser,
  getSharedUserData,
  updateUser,
  updateSharedUserData,
  resetProfileImage,
} from '../../configs/graphql/user';
import ConfirmActionDialog from '../Dialog/ConfirmActionDialog';
import {
  User,
  UserLevel,
  UpdateUserInput,
  DeleteUserInput,
  ProfileImageType,
  UpdateSharedUserDataInput,
} from '../../configs/types/User';

interface UserEditProps {
  user: User;
}

const UserEdit: React.FC<UserEditProps> = ({ user }: UserEditProps) => {
  const [firstName, setFirstName] = React.useState(user.firstName);
  const [lastName, setLastName] = React.useState(user.lastName);
  const [userLevel, setUserLevel] = React.useState(user.userLevel);
  const [enabled, setEnabled] = React.useState(user.enabled);

  const [
    isEditImageViewVisible,
    // toggleEditImageViewVisible,
  ] = React.useState(false);
  const [profileImageUrl, setProfileImageUrl] = React.useState('');
  const [dialogType, setDialogType] = React.useState('');
  const userLevelItems = [
    {
      text: 'Normal',
      value: 'NORMAL',
    },
    {
      text: 'Power user',
      value: 'POWER_USER',
    },
    {
      text: 'Admin',
      value: 'ADMIN',
    },
  ];
  const userEnabledItems = [
    {
      text: 'Enable',
      value: 'ENABLE',
    },
    {
      text: 'Disable',
      value: 'DISABLE',
    },
  ];

  const defaultProfileImageUrl = '';
  const userLevelMenuRef = useRef<MenuElement>();
  const userEnabledMenuRef = useRef<MenuElement>();
  const dialogRef = useRef<DialogElement>();

  // const addNewMenuRef = useRef<MenuElement>();

  /*
    when mounted setProfileImageUrl from  getSharedUserData
    */
  useEffect(() => {
    const getUserData = async () => {
      if (user.userId) {
        const { data } = await getSharedUserData(user.userId);
        if (data?.readSharedUserData?.profileImageUrl) {
          setProfileImageUrl(data?.readSharedUserData?.profileImageUrl);
        } else {
          console.log('no image');
        }
      }
    };
    getUserData();
  }, [user]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'firstName') {
      setFirstName(event.target.value);
    } else if (type === 'lastName') {
      setLastName(event.target.value);
    }
  };

  const handleCancel = () => {
    history.push('/user-management');
  };

  const handleOpenUserEnabledMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    userEnabledMenuRef?.current?.handleOpen(event);
  };

  const handleOpenUserLevelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    userLevelMenuRef?.current?.handleOpen(event);
  };

  const onUserLevelMenuItemSelect = (_type: string, item: MenuSelectItem) => {
    setUserLevel(item.value as UserLevel);
  };

  const onUserEnabledMenuItemSelect = (_type: string, item: MenuSelectItem) => {
    if (item.value === 'DISABLE') {
      setEnabled(false);
    } else if (item.value === 'ENABLE') {
      setEnabled(true);
    }
  };

  const handleOpenSaveDialog = () => {
    setDialogType('saveDialog');
    dialogRef?.current?.handleOpen();
  };

  const handleOpenDeleteDialog = () => {
    setDialogType('deleteDialog');
    dialogRef?.current?.handleOpen();
  };

  const handleOpenResetProfileImageDialog = () => {
    setDialogType('resetProfileImage');
    dialogRef?.current?.handleOpen();
  };

  const handleSave = async () => {
    if (firstName && lastName && user.userId && userLevel && user.email) {
      const userInput: UpdateUserInput = {
        userId: user.userId,
        firstName,
        lastName,
        userLevel,
        enabled,
      };
      const userDataResponse = await updateUser(userInput);
      const sharedUserInput: UpdateSharedUserDataInput = {
        email: user.email,
        firstName,
        lastName,
        userId: user.userId,
      };
      const sharedUserDataResponse = await updateSharedUserData(sharedUserInput);
      console.log('sharedUserDataResponse: ', sharedUserDataResponse);
      console.log('userDataResponse: ', userDataResponse);
      if (userDataResponse && sharedUserDataResponse) {
        history.push('/user-management');
      } else {
        // TODO handle error, maybe snackbar
      }
    }
  };

  const handleDelete = async () => {
    const userInput: DeleteUserInput = {
      userId: user.userId,
    };
    if (user.userId) {
      const data = await deleteUser(userInput);
      if (data) {
        history.push('/user-management');
      } else {
        // TODO handle error, maybe snackbar
      }
    }
  };

  const handleResetProfileImage = async () => {
    if (user.userId) {
      const data = await resetProfileImage(
        user.userId,
        defaultProfileImageUrl,
        ('PREDEFINED' as ProfileImageType),
      );
      console.log('data  ', data);
      // setProfileImageUrl("defaultProfileImageUrl")
    }
  };

  const onDialogConfirm = (type: string) => {
    switch (type) {
      case 'saveDialog':
        handleSave();
        break;
      case 'deleteDialog':
        handleDelete();
        break;
      case 'resetProfileImage':
        handleResetProfileImage();
        break;
      default:
        break;
    }
    dialogRef?.current?.handleClose();
  };

  const onDialogCancel = () => {
    dialogRef?.current?.handleClose();
  };

  // const handleOpenImageEditView = async () => {
  // if (isEditImageViewVisible === false) {
  // toggleEditImageViewVisible(true);
  // } else {
  // toggleEditImageViewVisible(false);
  // }
  // };

  let editImageView = <div />;
  if (isEditImageViewVisible === true) {
    editImageView = (
      <div className="user-edit-profile-image-edit-view">

        <div className="user-edit-profile-image-edit-view-url">
          <TextField
            label="Profile Image Url"
            multiline
            rowsMax={3}
            value={profileImageUrl}
            style={{ fontSize: 4 }}
            defaultValue="profileImageUrl"
            InputProps={{
              readOnly: true,
              style: { fontSize: 12, width: 180 },

            }}
          />
        </div>
        <IconButton
          aria-label="reset"
          color="secondary"
          onClick={handleOpenResetProfileImageDialog}
        >
          <RemoveCircleIcon />
        </IconButton>
      </div>
    );
  }
  let label = 'UNSET';
  if (enabled === true) {
    label = 'ENABLED';
  } else if (enabled === false) {
    label = 'DISABLED';
  }
  return (
    <div className="user-edit-view">
      <Paper
        elevation={3}
        className="user-edit-paper"
      >
        <Typography
          variant="h4"
        >
          Edit User
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          style={{
            marginBottom: 48,
          }}
        >
          {user.userId ? `Id: ${user.userId}` : ''}
        </Typography>

        <div className="user-edit-content">

          <div className="user-edit-first">

            <div className="user-edit-first-image-container">
              {/* resetting profile image disabled for now
              <div className="user-edit-first-edit-profileImage">
                {profileImageUrl !== ""
                ? <IconButton
                    onClick={handleOpenImageEditView}
                    color="primary"
                    aria-label="edit"
                  >
                    {isEditImageViewVisible === false ?  <EditIcon /> : <CloseIcon/>}
                  </IconButton>
                : <div></div>}
              </div> */}
              <img
                className="user-edit-profile-image"
                alt="Profile"
                src={profileImageUrl}
              />
            </div>
            {editImageView}

          </div>

          <div className="user-edit-second">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="user-edit-second-top">
                <Typography variant="h6">Email</Typography>
                <Typography
                  className="user-edit-second-top-item"
                  variant="body2"
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {user.email}
                </Typography>
                <Typography variant="h6">Name</Typography>
                <TextField
                  className="user-edit-second-top-item"
                  onChange={(
                    ev: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    handleOnChange(
                      ev, 'firstName',
                    );
                  }}
                  value={firstName}
                  label="First name"
                />
                <TextField
                  className="user-edit-second-top-item"
                  onChange={(
                    ev: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    handleOnChange(
                      ev, 'lastName',
                    );
                  }}
                  value={lastName}
                  label="Last name"
                />
              </div>
            </div>
          </div>

          <div className="user-edit-third">
            <div className="user-edit-third-item-top">
              <Typography variant="h6">User Level</Typography>
              <Button
                variant="contained"
                onClick={handleOpenUserLevelMenu}
              >
                {userLevel}
              </Button>

              <MenuSelect
                type="userLevel"
                ref={userLevelMenuRef}
                items={userLevelItems}
                onMenuItemSelect={onUserLevelMenuItemSelect}
              />

              <Typography variant="h6">Toggle Enabled</Typography>
              <Button
                variant="contained"
                onClick={handleOpenUserEnabledMenu}
              >
                {label}
              </Button>
              <MenuSelect
                type="userEnabled"
                ref={userEnabledMenuRef}
                items={userEnabledItems}
                onMenuItemSelect={onUserEnabledMenuItemSelect}
              />
            </div>

            <div className="user-edit-third-item-bottom">
              <Button onClick={handleOpenDeleteDialog}>Delete</Button>
              <Button
                onClick={handleCancel}
                variant="outlined"
                style={{
                  marginLeft: 32,
                  marginRight: 8,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenSaveDialog}
              >
                Save
              </Button>

            </div>

          </div>
        </div>

        <ConfirmActionDialog
          type={dialogType}
          ref={dialogRef}
          onConfirm={onDialogConfirm}
          onCancel={onDialogCancel}
        />
      </Paper>
    </div>
  );
};

export default UserEdit;
