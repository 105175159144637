import React, { useEffect } from 'react';

import { cognito } from './Cognito';

const Logout: React.FC = () => {
  useEffect(() => {
    const signOut = async () => {
      await cognito.signOut();
      window.location.href = '/login';
    };

    signOut();
  });

  return (
    <div />
  );
};

export default Logout;
