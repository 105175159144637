import {
  useState,
  useEffect,
  useCallback,
  FC,
  CSSProperties,
} from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AdvancedSettings from './Settings';

import { cognito } from '../CognitoLogin/Cognito';
import { LocalApiKeyDict } from '../CognitoLogin/utils';
import ProfileView from './ProfileView';
import PowerUserInfo from './PowerUserInfo';
import KeyContainer from './KeyContainer';
import PublicMap from './PublicMap';
import BasicInformation from './BasicInformation';

import {
  MAPDATA_URL,
} from '../../config';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapboxgl: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    steerpath: any;
  }
}

window.mapboxgl = window.mapboxgl || {};
window.steerpath = window.steerpath || {};

const style: {
  profile: CSSProperties,
  toggleAdvanced: CSSProperties,
  container: CSSProperties,
  mapStyle: CSSProperties,
} = {
  profile: {
    width: '90%',
    maxWidth: '960px',
    padding: '24px',
    marginBottom: '48px',
  },
  toggleAdvanced: {
    marginBottom: '24px',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  mapStyle: {
    width: '80%',
    height: '400px',
    backgroundColor: '#e2e0d7',
  },
};

const POWER_USER_CLICKS = 4;

type User = {
  sub: string,
  email: string,
  email_verified: string,
  given_name: string | null,
  family_name: string | null,
};

const Profile: FC = () => {
  const [credentials, setCredentials] = useState<LocalApiKeyDict>({} as LocalApiKeyDict);
  const [currentUser, setCurrentUser] = useState<User>({} as User);
  const [
    showAdvancedSettings,
    setShowAdvancedSettings,
  ] = useState(false);
  const [
    clickCounter,
    setClickCounter,
  ] = useState(0);
  const [
    isPowerUser,
    setIsPowerUser,
  ] = useState(false);

  const isOfficeAppUser = localStorage.getItem('fromOfficeApp') === 'true';

  const fetchUserInfo = async () => {
    try {
      const session = await cognito.refreshSession();
      const {
        sub,
        email,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        email_verified,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        given_name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        family_name,
      } = session.getIdToken().decodePayload();
      setCurrentUser({
        sub,
        email,
        email_verified,
        given_name,
        family_name,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getDefaultMapView = () => {
    // check if storage has recent view stored
    const localMapView = localStorage.getItem('mapView');
    if (localMapView) {
      return JSON.parse(localMapView);
    }

    return {
      center: {
        lng: 0,
        lat: 0,
      },
      bearing: 0,
      zoom: 0,
      pitch: 0,
    };
  };

  const initializeMap = useCallback((apiKeys: LocalApiKeyDict) => {
    const mapApiKey = (apiKeys.PUBLISHED_API_KEY_R
      ? apiKeys.PUBLISHED_API_KEY_R
      : apiKeys.PUBLISHED_API_KEY
    );
    const styleUrl = `${MAPDATA_URL}/default.json?access_token=${mapApiKey}`;
    const defaultMapView = getDefaultMapView();

    const mapboxMap = new window.mapboxgl.Map({
      container: 'mapboxMap',
      style: styleUrl,
      center: defaultMapView.center,
      zoom: defaultMapView.zoom,
      bearing: defaultMapView.bearing,
      pitch: defaultMapView.pitch,
      attributionControl: false,
      bearingSnap: 0,
      maxZoom: 22,
    });

    const steerpathMap = new window.steerpath.SteerpathMap(
      mapboxMap,
      mapApiKey,
    );

    mapboxMap.addControl(
      new window.steerpath.FloorSwitcherControl(steerpathMap),
      'bottom-right',
    );

    mapboxMap.on('moveend', () => {
      const mapView = {
        center: mapboxMap.getCenter(),
        bearing: mapboxMap.getBearing(),
        zoom: mapboxMap.getZoom(),
        pitch: mapboxMap.getPitch(),
      };

      localStorage.setItem('mapView', JSON.stringify(mapView));
    });
  }, []);

  useEffect(() => {
    const apiKeys = JSON.parse(localStorage.getItem('API_KEYS') || '{}');

    setCredentials(apiKeys);
    setIsPowerUser(localStorage.getItem('isPowerUser') === 'true');

    initializeMap(apiKeys);

    fetchUserInfo();
  }, [initializeMap]);

  const handleNameClick = () => {
    setClickCounter(clickCounter + 1);
    if (clickCounter === POWER_USER_CLICKS) {
      setIsPowerUser(!isPowerUser);

      const localIsPowerUser = localStorage.getItem('isPowerUser');
      if (localIsPowerUser) {
        localStorage.removeItem('isPowerUser');
      } else {
        localStorage.setItem('isPowerUser', 'true');
      }
      window.location.reload();
      setClickCounter(0);
    }
  };

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  return (
    <ProfileView>
      <Paper style={style.profile}>
        <div style={{ padding: '20px' }}>
          {currentUser.email
            && (
              <Typography
                variant="h3"
                gutterBottom
                onClick={handleNameClick}
              >
                {`${currentUser.given_name} ` || ''}
                {`${currentUser.family_name} ` || ''}
                {isOfficeAppUser ? '(Office App user)' : '(Dashboard user)'}
              </Typography>
            )}
          {isPowerUser && <PowerUserInfo />}

          <BasicInformation
            currentUser={currentUser}
            isPowerUser={isPowerUser}
          />
          <div style={{ marginBottom: '24px' }}>
            <Typography
              variant="h5"
              gutterBottom
            >
              Maps
            </Typography>
            <Typography
              style={{ maxWidth: '80%' }}
              gutterBottom
            >
              Start building applications with your indoor maps!
              Get started by checking out the Steerpath demo applications
              from Play Store and App Store.
              <a
                href="https://steerpath.com/indoor-positioning-platform-api"
                style={{ maxWidth: '80%' }}
                target="_blank"
                rel="noreferrer noopener"
              >
                We have examples, full API descriptions
                and getting started guides for each platform
              </a>
              {' '}
              Here is an example of your published web maps.
              Below you can find links to your web maps.
            </Typography>

            <div style={style.container}>
              <div style={{
                display: 'flex',
                flex: 1,
              }}
              >
                <div
                  id="mapboxMap"
                  style={style.mapStyle}
                />
              </div>
            </div>
          </div>

          {(credentials.PUBLISHED_API_KEY_R && credentials.LIVE_API_KEY_R ? (
            <PublicMap
              publishedKeyRead={credentials.PUBLISHED_API_KEY_R}
              liveKeyRead={credentials.LIVE_API_KEY_R}
            />
          ) : <div />)}

          <div style={{ marginBottom: '24px' }}>
            <Typography
              variant="h5"
              gutterBottom
            >
              API Keys
            </Typography>
            <Typography
              style={{ maxWidth: '80%' }}
              gutterBottom
            >
              Steerpath uses JSON Web Tokens (JWT) as the token format.
              Each token is a string delimited by dots into three parts:
              header, payload, and signature.
              You can decode your API key at
              &apos;
              <a
                href="https://jwt.io/"
                style={{ maxWidth: '80%' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                jwt.io
              </a>
              &nbsp; to see the API key access level and credentials.
              Your API keys are listed below.
            </Typography>

            {credentials.SMART_MAP_API_KEY
              && (
                <KeyContainer
                  title="Smart Map"
                  instruction="Use this API key with Steerpath Smart SDK. It uses data in publish-view."
                  keyList={[
                    {
                      apiKey: credentials.SMART_MAP_API_KEY,
                      accessLevel: 'read',
                    },
                  ]}
                />
              )}
            {credentials.PUBLISHED_API_KEY && credentials.PUBLISHED_API_KEY_R
              && (
                <KeyContainer
                  title="Production"
                  instruction="Use the read API key in your applications and websites"
                  keyList={[
                    {
                      apiKey: credentials.PUBLISHED_API_KEY_R,
                      accessLevel: 'read',
                    },
                    {
                      apiKey: credentials.PUBLISHED_API_KEY,
                      accessLevel: 'read, write, merge',
                    },
                  ]}
                />
              )}

            {credentials.LIVE_API_KEY
              && (
                <KeyContainer
                  title="Steerpath Live"
                  instruction="Use these API keys for Steerpath Live services"
                  keyList={[
                    {
                      apiKey: credentials.LIVE_API_KEY_R || '',
                      accessLevel: 'read',
                    },
                    {
                      apiKey: credentials.LIVE_API_KEY,
                      accessLevel: 'read, write, merge',
                    },
                  ]}
                />
              )}

            {credentials.EDITOR_API_KEY && credentials.EDITOR_API_KEY_R
              && (
                <KeyContainer
                  title="Editor"
                  keyList={[
                    {
                      apiKey: credentials.EDITOR_API_KEY_R,
                      accessLevel: 'read',
                    },
                    {
                      apiKey: credentials.EDITOR_API_KEY,
                      accessLevel: 'read, write, merge',
                    },
                  ]}
                />
              )}

            {credentials.IMPORT_API_KEY
              && (
                <KeyContainer
                  title="Import"
                  keyList={[
                    {
                      apiKey: credentials.IMPORT_API_KEY,
                      accessLevel: 'read, write, merge',
                    },
                  ]}
                />
              )}

            {credentials.METALIVE_API_KEY
              && (
                <KeyContainer
                  title="Meta Live"
                  keyList={[
                    {
                      apiKey: credentials.METALIVE_API_KEY_R || '',
                      accessLevel: 'read',
                    },
                    {
                      apiKey: credentials.METALIVE_API_KEY,
                      accessLevel: 'read, write, merge',
                    },
                  ]}
                />
              )}
          </div>

          {currentUser.email && isPowerUser
            && (
              <div>
                <button
                  type="button"
                  style={style.toggleAdvanced}
                  onClick={toggleAdvancedSettings}
                >
                  {
                    showAdvancedSettings
                      ? 'Hide advanced settings'
                      : 'Show advanced settings'
                  }
                </button>
                <br />
              </div>
            )}

          {showAdvancedSettings && <AdvancedSettings />}

        </div>
      </Paper>

    </ProfileView>
  );
};

export default Profile;
