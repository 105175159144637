import {
  useState,
  useEffect,
  useCallback,
} from 'react';

/*
 * Save apikeys to local storage
 */

type ApiKeyDict = {
  editorKeyReadWriteMerge: string | null,
  editorKeyRead: string | null,
  importKeyReadWriteMerge: string | null,
  publishedKeyReadWriteMerge: string | null,
  publishedKeyRead: string | null,
  liveKeyReadWriteMerge: string | null,
  liveKeyRead: string | null,
  metaLiveKeyReadWriteMerge: string | null,
  metaLiveKeyRead: string | null,

  smartmapAPIKey?: string | null,
};

export type LocalApiKeyDict = {
  EDITOR_API_KEY: string | null,
  IMPORT_API_KEY: string | null,
  PUBLISHED_API_KEY: string | null,
  LIVE_API_KEY: string | null,
  METALIVE_API_KEY: string | null,

  EDITOR_API_KEY_R: string | null,
  PUBLISHED_API_KEY_R: string | null,
  LIVE_API_KEY_R: string | null,
  METALIVE_API_KEY_R: string | null,

  SMART_MAP_API_KEY?: string | null,
};

const saveAPIKeys = ({
  editorKeyReadWriteMerge,
  editorKeyRead,
  importKeyReadWriteMerge,
  publishedKeyReadWriteMerge,
  publishedKeyRead,
  liveKeyReadWriteMerge,
  liveKeyRead,
  metaLiveKeyReadWriteMerge,
  metaLiveKeyRead,
  smartmapAPIKey,
}: ApiKeyDict): void => {
  const apiKeys: LocalApiKeyDict = {
    EDITOR_API_KEY: editorKeyReadWriteMerge,
    IMPORT_API_KEY: importKeyReadWriteMerge,
    PUBLISHED_API_KEY: publishedKeyReadWriteMerge,
    LIVE_API_KEY: liveKeyReadWriteMerge,
    METALIVE_API_KEY: metaLiveKeyReadWriteMerge,

    EDITOR_API_KEY_R: editorKeyRead,
    PUBLISHED_API_KEY_R: publishedKeyRead,
    LIVE_API_KEY_R: liveKeyRead,
    METALIVE_API_KEY_R: metaLiveKeyRead,

    SMART_MAP_API_KEY: smartmapAPIKey,
  };

  if (!editorKeyReadWriteMerge) { throw new Error('Expected EDITOR_API_KEY'); }
  if (!importKeyReadWriteMerge) { throw new Error('Expected IMPORT_API_KEY'); }
  if (!publishedKeyReadWriteMerge) { throw new Error('Expected PUBLISHED_API_KEY'); }

  localStorage.setItem('API_KEYS', JSON.stringify(apiKeys));
};

let authGlobalState = localStorage.getItem('isLoggedIn') === 'true';
const authListeners = new Set<() => void>();

const useAuth = (): {
  auth: boolean,
  setAuth: (authState: boolean) => void,
} => {
  const [authState, setAuthState] = useState(authGlobalState);

  useEffect(() => {
    const listener = () => setAuthState(authGlobalState);
    authListeners.add(listener);
    listener();

    return () => {
      authListeners.delete(listener);
    };
  }, []);

  const setAuth = useCallback((nextAuthState: boolean) => {
    authGlobalState = nextAuthState;
    authListeners.forEach((listener) => listener());

    if (nextAuthState) {
      localStorage.setItem('isLoggedIn', 'true');
    } else {
      localStorage.removeItem('isLoggedIn');
    }
  }, []);

  return {
    auth: authState,
    setAuth,
  };
};

export {
  saveAPIKeys,
  useAuth,
};
