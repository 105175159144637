/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button, Paper } from '@material-ui/core';

import { getEmailAddressPatternByOrganization } from '../../configs/graphql/emailPattern';
import { readOrgData } from '../../configs/graphql/org';
import {
  getUserInfo,
  getUsers,
} from '../../configs/graphql/user';
import { cognito } from '../CognitoLogin/Cognito';

import TableComponent, { TableTitles } from './TableComponent';
import './styles.scss';
import {
  MenuElement,
  MenuSelectItem,
  SnackbarMessageElement,
  TableComponentElement,
} from '../../configs/types/Common';
import { User } from '../../configs/types/User';
import { EmailPattern } from '../../configs/types/EmailPattern';
import { IdentityProvider, UpdateOrgInput } from '../../configs/types/Org';
import { filterUsers } from './utils';
import AddNewCard from './AddNewCard';
import MenuSelect from './MenuSelect';
import SnackbarMessage from '../Dialog/SnackbarMessage';

const UserManagement: React.FC = () => {
  const [registeredUsers, setRegisteredUsers] = React.useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);

  const [invitedUsers, setInvitedUsers] = React.useState<EmailPattern[]>([]);
  const [filteredInvitedUsers, setFilteredInvitedUsers] = React.useState<EmailPattern[]>([]);

  const [orgId, setOrgId] = React.useState('0');
  const [orgName, setOrgName] = React.useState('');
  const [idpFederationProvider, setIdpFederationProvider] = React.useState<IdentityProvider | undefined>();
  const [idpFederationTenantName, setIdpFederationTenantName] = React.useState<string | undefined>();

  const [isAddNewCardVisible, toggleAddNewCardVisible] = React.useState(false);
  const [addNewCardMode, setAddNewCardMode] = React.useState<MenuSelectItem['value']>('');

  const addNewMenuRef = useRef<MenuElement>();
  const registeredUsersTableRef = useRef<TableComponentElement>();
  const approvedEmailsTableRef = useRef<TableComponentElement>();

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();

  const getInvitees = async (organizationId: string) => {
    const { data, errors } = await getEmailAddressPatternByOrganization(organizationId);
    console.log('getInvitees', data?.readAllEmailPatternsByOrg?.items);

    if (
      data?.readAllEmailPatternsByOrg?.items
      && data.readAllEmailPatternsByOrg.items.length > 0
    ) {
      const invitees = data?.readAllEmailPatternsByOrg?.items;
      if (invitees.length > 0) {
        setInvitedUsers(invitees);
        setFilteredInvitedUsers(invitees);
      }
    } else if (errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach((error: Error) => {
        errorMessages.push(error.message);
      });
      setSnackbarMessage(`Could not load Invitees ${errorMessages}`);
      snackbarRef?.current?.handleOpen();
    }
  };

  const getRegisteredUsers = async (organizationId: string) => {
    const { data, errors } = await getUsers(organizationId);
    if (data?.readAllUsersByOrg?.items) {
      const registeredUserList = data?.readAllUsersByOrg?.items;
      if (registeredUserList.length > 0) {
        setRegisteredUsers(registeredUserList);
        setFilteredUsers(registeredUserList);
      }
    } else if (errors && errors.length > 0) {
      const errorMessages: string[] = [];
      errors.forEach((error: Error) => {
        errorMessages.push(error.message);
      });
      setSnackbarMessage(`Could not load Registered users ${errorMessages}`);
      snackbarRef?.current?.handleOpen();
    }
  };

  const getOrganizationData = async (organizationId: string) => {
    const { data } = await readOrgData(organizationId);
    if (data?.readOrg?.orgName) {
      setOrgName(data?.readOrg?.orgName);
      setIdpFederationProvider(data?.readOrg?.idpFederationProvider);
      setIdpFederationTenantName(data?.readOrg?.idpFederationTenantName);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { attributes: { sub } } = await cognito.user();
      const { data } = await getUserInfo(sub);
      if (data?.readUser?.orgId) {
        setOrgId(data?.readUser.orgId);
        getInvitees(data?.readUser.orgId);
        getRegisteredUsers(data?.readUser.orgId);
        getOrganizationData(data?.readUser.orgId);
      }
    };
    getData();
  }, []);

  const filterContent = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (event.target.value) {
      let result : Array<EmailPattern | User | UpdateOrgInput> = [];
      if (type === 'registeredUsers') {
        result = filterUsers(event.target.value, registeredUsers, 'userId');
        setFilteredUsers(result as User[]);
        registeredUsersTableRef?.current?.handleSetPage();
      } else if (type === 'approvedEmails') {
        result = filterUsers(event.target.value, invitedUsers);
        setFilteredInvitedUsers(result as EmailPattern[]);
        approvedEmailsTableRef?.current?.handleSetPage();
      }
    } else if (type === 'registeredUsers') {
      setFilteredUsers(registeredUsers as User[]);
    } else if (type === 'approvedEmails') {
      setFilteredInvitedUsers(invitedUsers as EmailPattern[]);
    }
  };
  const registeredUsersType = 'registeredUsers';
  const registeredUsersTableTitles: TableTitles[] = [
    // {
    //   title: 'First Name',
    //   id: 'firstName',
    //   numeric: false,
    // },
    // {
    //   title: 'Last Name',
    //   id: 'lastName',
    //   numeric: false,
    // },
    {
      title: 'Email',
      id: 'email',
      numeric: false,
    },
    {
      title: 'Created',
      id: 'createdAt',
      numeric: true,
    },
    {
      title: 'Updated',
      id: 'updatedAt',
      numeric: true,
    },
    {
      title: 'Active',
      id: 'lastActiveTime',
      numeric: true,
    },
    {
      title: 'Level',
      id: 'userLevel',
      numeric: false,
    },
    {
      title: 'Edit',
      id: 'edit',
      numeric: false,
    },
  ];

  const registeredUsersTableColumns: string[] = [
    // 'firstName',
    // 'lastName',
    'email',
    'createdAt',
    'updatedAt',
    'lastActiveTime',
    'userLevel',
  ];

  const approvedEmailsType = 'approvedEmails';
  const approvedEmailsTableTitles: TableTitles[] = [
    {
      title: 'Email',
      id: 'addressPattern',
      numeric: false,
    },
    {
      title: 'Created',
      id: 'createdAt',
      numeric: true,
    },
    {
      title: 'Level',
      id: 'defaultUserLevel',
      numeric: false,
    },
    {
      title: 'Edit',
      id: 'edit',
      numeric: false,
    },
  ];
  const approvedEmailsTableColumns: string[] = [
    'addressPattern',
    'createdAt',
    'defaultUserLevel',
  ];

  const handleOpenAddNewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    addNewMenuRef?.current?.handleOpen(event);
  };

  const onaddNewMenuItemSelect = (_type: string, item: MenuSelectItem): void => {
    toggleAddNewCardVisible(true);
    setAddNewCardMode(item.value);
  };

  const onCancelAddNewCard = () => {
    toggleAddNewCardVisible(false);
  };

  const updateInvitees = () => {
    toggleAddNewCardVisible(false);
    if (orgId) {
      getInvitees(orgId);
    }
  };

  let addNewUserView = <div />;
  if (isAddNewCardVisible === true) {
    addNewUserView = (
      <AddNewCard
        onCancelAddNewCard={onCancelAddNewCard}
        updateInvitees={updateInvitees}
        mode={addNewCardMode}
        orgId={orgId}
        idpFederationProvider={idpFederationProvider}
        idpFederationTenantName={idpFederationTenantName}
      />
    );
  }

  const addNewItems = [
    {
      value: 'add_new_sso_email',
      text: 'Add new SSO user',
    },
    {
      value: 'add_new_sso_domain',
      text: 'Add new SSO domain',

    },
    {
      value: 'add_new_email_password',
      text: 'Invite new email + passoword user',
    },
  ];

  return (
    <div className="user-management">
      <Paper className="user-management-paper">
        <div>
          <Typography variant="h4">User Management</Typography>
          <Typography variant="body2">{orgName}</Typography>
          <div className="user-management-sub-title">
            <Typography variant="h5">Registered Users</Typography>
            <TextField
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => {
                filterContent(
                  ev, registeredUsersType,
                );
              }}
              label="Search"
            />
          </div>
          <TableComponent
            ref={registeredUsersTableRef}
            type={registeredUsersType}
            tableTitles={registeredUsersTableTitles}
            columns={registeredUsersTableColumns}
            data={filteredUsers}
          />

          <div className="user-management-sub-title">
            <div className="user-management-sub-title-approved-emails">
              <Typography variant="h5">Invitees and Enabled Domains</Typography>

              {isAddNewCardVisible === false
                ? (
                  <Button
                    className="user-management-sub-title-approved-emails-button"
                    onClick={handleOpenAddNewMenu}
                    variant="contained"
                    color="primary"
                  >
                    New
                  </Button>
                )
                : <div />}

              <MenuSelect
                type="addNew"
                ref={addNewMenuRef}
                items={addNewItems}
                onMenuItemSelect={onaddNewMenuItemSelect}
              />

            </div>
            <TextField
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => {
                filterContent(
                  ev, approvedEmailsType,
                );
              }}
              label="Search"
            />
          </div>
          {addNewUserView}
          <TableComponent
            ref={approvedEmailsTableRef}
            type={approvedEmailsType}
            tableTitles={approvedEmailsTableTitles}
            columns={approvedEmailsTableColumns}
            data={filteredInvitedUsers}
            updateInvitees={updateInvitees}
          />

          <SnackbarMessage
            message={snackbarMessage}
            ref={snackbarRef}
          />
        </div>
      </Paper>
    </div>
  );
};

export default UserManagement;
