import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import gql from 'graphql-tag';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';

import {
  DeleteUserInput,
  DeleteUserMutation,
  ProfileImageType,
  ReadAllUsersByOrgQuery,
  ReadSharedUserDataQuery,
  ReadUserQuery,
  UpdateSharedUserDataInput,
  UpdateSharedUserDataMutation,
  UpdateUserInput,
  UpdateUserMutation,
  UserLevel,
} from '../types/User';

export const getUserInfo = async (
  userId: string,
): Promise<GraphQLResult<ReadUserQuery>> => {
  const res = await API.graphql(graphqlOperation(gql`
    query readUser {
      readUser(userId: "${userId}") {
        email
        userLevel
        orgId
      }
    }
  `)) as GraphQLResult<ReadUserQuery>;

  return res;
};

export const getUsers = async (
  orgId: string,
): Promise<GraphQLResult<ReadAllUsersByOrgQuery>> => {
  const res = await API.graphql(graphqlOperation(gql`
    query readAllUsersByOrg {
      readAllUsersByOrg(orgId: "${orgId}") {
        items {
          userId,
          email,
          firstName,
          lastName,
          available,
          userLevel,
          createdAt,
          updatedAt,
          enabled,
          lastActiveTime,
        }
      }
    }
  `)) as GraphQLResult<ReadAllUsersByOrgQuery>;
  return res;
};

export const updateUser = async (
  user: UpdateUserInput,
): Promise<GraphQLResult<UpdateUserMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation updateUser {
      updateUser(input: {
        userLevel: "${(user.userLevel as UserLevel)}",
        userId: "${user.userId}",
        lastName: "${user.lastName}",
        enabled: ${user.enabled},
        firstName: "${user.firstName}"
      }) {
        userId
      }
    }
    `)) as GraphQLResult<UpdateUserMutation>;

  return res;
};

export const deleteUser = async (
  user: DeleteUserInput,
): Promise<GraphQLResult<DeleteUserMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation deleteUser {
      deleteUser(userId: "${user.userId}") {
        userId
      }
    }
    `)) as GraphQLResult<DeleteUserMutation>;

  return res;
};

export const getSharedUserData = async (
  userId: string,
): Promise<GraphQLResult<ReadSharedUserDataQuery>> => {
  const res = await API.graphql(
    graphqlOperation(gql`
      query readSharedUserData {
        readSharedUserData(userId: "${userId}") {
          profileImageType
          profileImageUrl
        }
      }
    `),
  ) as GraphQLResult<ReadSharedUserDataQuery>;
  return res;
};

/* resetting profile image disabled */
export const resetProfileImage = async (
  email: string,
  profileImageUrl: string,
  profileImageType: ProfileImageType,
): Promise<GraphQLResult<UpdateSharedUserDataMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
    mutation updateSharedUserData {
      updateSharedUserData(input: {
        email: "${email}",
        profileImageUrl: "${profileImageUrl}",
        profileImageType: ${profileImageType}
      }) {
        email,
        profileImageUrl,
        profileImageType
      }
    }
  `)) as GraphQLResult<UpdateSharedUserDataMutation>;

  return res;
};

export const updateSharedUserData = async (
  user: UpdateSharedUserDataInput,
): Promise<GraphQLResult<UpdateSharedUserDataMutation>> => {
  const res = await API.graphql(graphqlOperation(gql`
  mutation updateSharedUserData {
    updateSharedUserData(input: {
      userId: "${user.userId}",
      email: "${user.email}",
      lastName: "${user.lastName}",
      firstName: "${user.firstName}"
    }) {
      userId
    }
  }
  `)) as GraphQLResult<UpdateSharedUserDataMutation>;

  return res;
};
