/* eslint-disable class-methods-use-this */
// Cognito type in AWS Amplify is a mess
// So we should use `any` type rather than
// spending time reading the source code for some internal types
// Not worth it

import {
  CognitoUserSession,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm';
import { ConfirmSignUpOptions } from '@aws-amplify/auth/src/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ICredentials } from '@aws-amplify/core';

import {
  orgAwsConfig,
  dashboardAwsConfig,
} from './config';

class Cognito {
  poolName: string;

  constructor() {
    const fromOfficeApp = localStorage.getItem('fromOfficeApp') === 'true';
    if (fromOfficeApp) {
      Amplify.configure(orgAwsConfig);
      this.poolName = 'OFFICE_APP';
    } else {
      Amplify.configure(dashboardAwsConfig);
      this.poolName = 'DASHBOARD';
    }
  }

  switchToOfficeAppPool(): void {
    console.log('Switch to office app config');
    Amplify.configure(orgAwsConfig);
    this.poolName = 'OFFICE_APP';
  }

  switchToDashboardAppPool(): void {
    console.log('Switch to dashboard config');
    Amplify.configure(dashboardAwsConfig);
    this.poolName = 'DASHBOARD';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user(): any {
    return Auth.currentAuthenticatedUser();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) return true;
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signIn(email: string, password: string): Promise<any> {
    return Auth.signIn(email, password);
  }

  googleSignIn(): Promise<ICredentials> {
    // When this.poolName === 'OFFICE_APP'
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }

  async refreshSession(): Promise<CognitoUserSession> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    return new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        // eslint-disable-next-line consistent-return
        (err: Error, session: CognitoUserSession) => {
          if (err) return reject(err);
          resolve(session);
        },
      );
    });
  }

  signOut(): Promise<void> {
    localStorage.clear();
    return Auth.signOut();
  }

  async getSession(): Promise<CognitoUserSession> {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line consistent-return
      cognitoUser.getSession((err: Error, currentSession: CognitoUserSession) => {
        if (err) return reject(err);
        if (!currentSession.isValid()) {
          const refreshToken = currentSession.getRefreshToken();
          cognitoUser.refreshSession(
            refreshToken,
            (refreshSessionError: Error, session: CognitoUserSession): void => {
              if (refreshSessionError) reject(refreshSessionError);
              else resolve(session);
            },
          );
        }

        resolve(currentSession);
      });
    });
  }

  async signUp(
    email: string,
    password: string,
    attributes: { [key: string]: string | null },
  ): Promise<CognitoUser> {
    if (!email) throw new Error('Expected a email');
    if (!password) throw new Error('Expected a password');
    if (this.poolName === 'DASHBOARD') {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          ...attributes,
        },
      });

      return user;
    }

    // poolName === 'OFFICE_APP'
    console.log('OFFICE_APP', attributes);
    // TODO continue from here
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        ...attributes,
      },
    });

    return user;
  }

  confirmRegistration(
    email: string,
    code: string,
    options?: ConfirmSignUpOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    if (this.poolName === 'DASHBOARD') {
      return Auth.confirmSignUp(email, code);
    }

    // this.poolName === 'OFFICE_APP'
    console.log('OFFICE_APP');
    return Auth.confirmSignUp(email, code, options);
  }

  async resetPassword(username: string): Promise<unknown> {
    if (this.poolName === 'DASHBOARD') {
      const data = await Auth.forgotPassword(username);
      return data;
    }

    return null;
  }

  async confirmPassword(
    email: string,
    verificationCode: string,
    newPassword: string,
  ): Promise<unknown> {
    if (this.poolName === 'DASHBOARD') {
      const data = await Auth.forgotPasswordSubmit(
        email,
        verificationCode,
        newPassword,
      );

      return data;
    }

    return null;
  }

  async getUserAttributes(): Promise<{ [key: string]: string }> {
    const { attributes } = await Auth.currentUserInfo();
    return attributes;
  }

  // TODO: Create generic method to update user attributes
  async changeEmail(newEmail: string): Promise<string> {
    // When this.poolName === 'DASHBOARD'
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return Auth.updateUserAttributes(
      cognitoUser,
      {
        email: newEmail,
      },
    );
  }
}

export default Cognito;
export const cognito = new Cognito();
