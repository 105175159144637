import { User } from '../../configs/types/User';
import { EmailPattern } from '../../configs/types/EmailPattern';
import { Org } from '../../configs/types/Org';

type DataContent = User | EmailPattern | Org;
type Data = DataContent[];
export const filterUsers = (value: string, data: Data, identifierKey?: 'userId' | 'orgId'): Data => {
  const result: Data = [];
  const addedItems: string[] = [];
  const lowerCaseValue = value.toLocaleLowerCase();
  data.forEach((item: DataContent) => {
    if (item) {
      const id = identifierKey === 'userId' ? (item as User).userId : (item as Org).orgId;
      Object.values(item).forEach((compareTo) => {
        if (
          compareTo
            && typeof compareTo === 'string'
            && compareTo.toLocaleLowerCase().includes(lowerCaseValue)
            && !addedItems.includes(id)
        ) {
          addedItems.push(id);
          result.push(item);
        }
      });
    }
  });
  return result;
};
