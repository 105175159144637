import {
  FC,
  CSSProperties,
} from 'react';

import Typography from '@material-ui/core/Typography';

import {
  MAPDATA_URL,
} from '../../config';
import WEB from '../../public/web.png';
import WEB_KIOSK from '../../public/web-kiosk.png';

const style: {
  container: CSSProperties,
  linkContainer: CSSProperties,
  mapLink: CSSProperties,
  imageContainer: CSSProperties,
  webImage: CSSProperties,
  kioskImage: CSSProperties,
  androidImage: CSSProperties,
} = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },

  linkContainer: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginRight: '10px',
  },

  mapLink: {
    maxWidth: '80%',
  },

  imageContainer: {
    display: 'flex',
    flex: 3,
    maxWidth: '500px',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },

  webImage: {
    width: '100%',
    height: '100%',
  },

  kioskImage: {
    width: '100%',
    height: '100%',
  },

  androidImage: {
    width: '190px',
    height: '400px',
  },
};

type PublicMapProps = {
  publishedKeyRead: string,
  liveKeyRead: string | null,
};

const PublicMap: FC<PublicMapProps> = ({
  publishedKeyRead,
  liveKeyRead,
}: PublicMapProps) => {
  const publicMapLink = `${MAPDATA_URL}/index.html?access_token=${publishedKeyRead}`;
  const publicMapLinkWithLive = `${MAPDATA_URL}/live.html?access_token=${publishedKeyRead}&live_access_token=${liveKeyRead}`;
  const publicKioskMapLink = `${MAPDATA_URL}/kiosk.html?access_token=${publishedKeyRead}`;
  const publicKioskMapLinkWithLive = `${MAPDATA_URL}/kiosk.html?access_token=${publishedKeyRead}&live_access_token=${liveKeyRead}`;

  return (
    <div style={{ marginBottom: '24px' }}>
      <div style={style.container}>
        <div style={style.linkContainer}>
          <Typography
            variant="h6"
            gutterBottom
          >
            Web
          </Typography>
          <Typography>
            Web maps with
            {' '}
            <b>Steerpath Core SDK</b>
            .
          </Typography>
          <a
            href={publicMapLink}
            style={style.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography noWrap>
              Web Maps
            </Typography>
          </a>
          <a
            href={publicMapLinkWithLive}
            style={style.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography noWrap>
              Web Maps (Steerpath Live enabled)
            </Typography>
          </a>
        </div>

        <div style={style.imageContainer}>
          <img
            style={style.webImage}
            src={WEB}
            alt="WEB"
          />
        </div>
      </div>

      <br />

      <div style={style.container}>
        <div style={style.linkContainer}>
          <Typography
            variant="h6"
            gutterBottom
          >
            Kiosk
          </Typography>
          <Typography>
            Kiosk maps with
            &apos;
            <b>Steerpath Smart SDK</b>
            . Smart SDK contains ready-made features and solutions
            such as search, selection and navigation.
          </Typography>
          <a
            href={publicKioskMapLink}
            style={style.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography noWrap>
              Kiosk Maps
            </Typography>
          </a>
          <a
            href={publicKioskMapLinkWithLive}
            style={style.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography noWrap>
              Kiosk Maps (Steerpath Live enabled)
            </Typography>
          </a>

        </div>
        <div style={style.imageContainer}>
          <img
            style={style.kioskImage}
            src={WEB_KIOSK}
            alt="WEB_KIOSK"
          />
        </div>
      </div>
    </div>
  );
};

export default PublicMap;
