import {
  FC,
} from 'react';

import Typography from '@material-ui/core/Typography';

const style = {
  color: 'orangered',
  marginTop: '16px',
  marginBottom: '16px',
};

const PowerUserInfo: FC = () => (
  <Typography style={style}>
    You have unlocked the Power User Mode!
  </Typography>
);

export default PowerUserInfo;
