import {
  useState,
  useEffect,
  useCallback,
  FC,
} from 'react';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Auth } from 'aws-amplify';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoginView from './LoginView';
import {
  saveAPIKeys,
  useAuth,
} from './utils';
import { getUserInfo } from '../../configs/graphql/user';
import { readOrgData } from '../../configs/graphql/org';
import { validateEmailPattern } from '../../configs/graphql/emailPattern';
import { cognito } from './Cognito';
import { O365_PROVIDER_URL } from '../../config';
import { UserLevel } from '../../configs/types/User';

const style = {
  loginButton: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  errorMessage: {
    color: 'red',
  },
};

interface OrgLoginProps {
  type?: string;
}

const OrgLogin: FC<OrgLoginProps> = ({ type }: OrgLoginProps) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const [loginType, setLoginType] = useState('');
  const [o365exchangeDomain, setO365exchangeDomain] = useState('');
  const [providers, setProviders] = useState<{ [key: string]: string }>({});

  const history = useHistory();
  const { setAuth } = useAuth();

  const fetchProvider = async () => {
    try {
      const response = await fetch(O365_PROVIDER_URL);
      if (response) {
        const providerData = await response.json();
        setProviders(providerData);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  // eslint-disable-next-line consistent-return
  const fetchUserData = useCallback(async (username: string) => {
    const { data } = await getUserInfo(username);
    if (!data?.readUser?.email || !data?.readUser?.orgId || !data?.readUser?.userLevel) {
      throw new Error('Missing some of the user attributes');
    }
    if (
      data?.readUser?.userLevel !== UserLevel.ADMIN
      && data?.readUser?.userLevel !== UserLevel.SUPER_ADMIN
    ) {
      throw new Error('Only admin users are allowed');
    }

    const { data: orgData } = await readOrgData(data?.readUser?.orgId);
    if (!orgData?.readOrg) {
      throw new Error('This user does not belong to any org');
    }

    const { readOrg } = orgData;
    const {
      editorKeyReadWriteMerge,
      editorKeyRead,
      importKeyReadWriteMerge,
      publishedKeyReadWriteMerge,
      publishedKeyRead,
      liveKeyReadWriteMerge,
      liveKeyRead,
      metaLiveKeyReadWriteMerge,
      metaLiveKeyRead,
      smartmapAPIKey,
    } = readOrg;

    saveAPIKeys({
      editorKeyReadWriteMerge,
      editorKeyRead,
      importKeyReadWriteMerge,
      publishedKeyReadWriteMerge,
      publishedKeyRead,
      liveKeyReadWriteMerge,
      liveKeyRead,
      metaLiveKeyReadWriteMerge,
      metaLiveKeyRead,
      smartmapAPIKey,
    });
    localStorage.setItem('loginType', loginType);
    localStorage.setItem('userLevel', data?.readUser?.userLevel);
    setAuth(true);
    localStorage.setItem('fromOfficeApp', 'true');
    if (process.env.REACT_APP_ENV === 'development') {
      return history.push('/profile');
    }

    window.location.href = '/profile';
  }, [history, setAuth, loginType]);

  useEffect(() => {
    const checkAuthenticated = async () => {
      try {
        setShowLoginSpinner(true);
        cognito.switchToOfficeAppPool();

        const isAuthenticated = await cognito.isAuthenticated();
        if (isAuthenticated) {
          const {
            attributes: { sub },
          } = await cognito.user();

          // TODO set the loginType from identities providerName either Microsoft or Google
          /* {
            "sub": "03bd6145-776e-48b2-b628-70c926292837",
            "identities": "[{
              "userId": "K9JDn6QWXCgwNBcus6UEf3CWKE-s8K94J0HvalMPTSI",
              "providerName": "Microsoft",
              "providerType": "OIDC",
              "issuer":null,
              "primary":true,
              "dateCreated":1591876535090
            }]",
            "email_verified": false,
            "name": "John Doe",
            "given_name": "John",
            "family_name": "Doe",
            "email": "john.doe@steerpath.live"
          } */
          await fetchUserData(sub);
          setShowLoginSpinner(false);
        } else {
          localStorage.clear();
          setShowLoginSpinner(false);
        }
      } catch (err: any) {
        setErrorMessage(err.message);
        localStorage.clear();
        setShowLoginSpinner(false);
        console.error(err);
      }
    };

    checkAuthenticated();
  }, [fetchUserData]);

  const loginWithMicrosoft = useCallback(() => {
    setLoginType('o365exchange');
  }, []);

  const loginWithSteerpath = useCallback(() => {
    setLoginType('steerpath');
  }, []);

  const loginWithGoogle = useCallback(async () => {
    setLoginType('gsuite');
    // redirectToGoogleLogin
    try {
      await cognito.googleSignIn();
    } catch (err: any) {
      setErrorMessage(err.message);
      console.error(err);
    }
  }, []);

  const redirectToMicrosoftLogin = async () => {
    if (validateEmailPattern(o365exchangeDomain)) {
      const providerName = providers[`@${o365exchangeDomain}`];
      if (providerName) {
        try {
          const res = await Auth.federatedSignIn({ customProvider: providerName });
          // clearState()
          console.log({ res });
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage('The given domain cannot be used for logging in to the application');
      }
    } else {
      setErrorMessage('Invalid email');
    }
  };

  const loginWithSmartOffice = useCallback(async () => {
    setErrorMessage('');

    if (!email) {
      setErrorMessage('Email is required.'); return;
    }
    if (!password) {
      setErrorMessage('Password is required.');
      return;
    }

    setShowLoginSpinner(true);

    try {
      const { username } = await cognito.signIn(email, password);
      await fetchUserData(username);
    } catch (err: any) {
      setErrorMessage(err.message);
      setShowLoginSpinner(false);
      console.error(err);
    }
  }, [email, password, fetchUserData]);

  useEffect(() => {
    if (type) {
      fetchProvider();
      switch (type) {
        case 'gsuite':
          loginWithGoogle();
          break;
        case 'o365exchange':
          loginWithMicrosoft();
          break;
        case 'steerpath':
          loginWithSteerpath();
          break;
        default:
          break;
      }
    } else if (window.location.search.includes('?error_description=')) {
      setErrorMessage('Something went wrong please try again');
    }
  }, [type, loginWithGoogle, loginWithSteerpath, loginWithMicrosoft]);

  const loadingCircle = (
    <div style={{
      marginLeft: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {showLoginSpinner && <CircularProgress size={32} />}
    </div>
  );
  const renderLogin = () => {
    if (loginType === 'steerpath') {
      return (
        <div>
          <TextField
            autoFocus
            fullWidth
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />

          <TextField
            fullWidth
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />

          <Typography>
            {'Don\'t have an account yet? '}
            <Link
              to={{
                pathname: '/signup',
                search: '?type=organization',
                state: { type: 'organization' },
              }}
            >
              Register
            </Link>
          </Typography>

          <p style={style.errorMessage}>
            {errorMessage}
          </p>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={style.loginButton}
              variant="contained"
              color="primary"
              onClick={loginWithSmartOffice}
              disabled={showLoginSpinner}
            >
              {
                showLoginSpinner
                  ? 'Logging in ...'
                  : 'Login'
              }
            </Button>
            {loadingCircle}
          </div>
        </div>
      );
    }
    if (loginType === 'o365exchange') {
      return (
        <div>
          <Typography>
            Please type in your organisation domain,
            so we can redirect you to login with your correct corporate account.
          </Typography>
          <TextField
            fullWidth
            helperText="example.com"
            label="Microsoft Domain"
            id="o365exchangeDomain"
            onChange={(e) => setO365exchangeDomain(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }}
          />
          <p style={style.errorMessage}>
            {errorMessage}
          </p>
          <Button
            style={style.loginButton}
            variant="contained"
            color="primary"
            onClick={redirectToMicrosoftLogin}
            disabled={showLoginSpinner}
          >
            {
              showLoginSpinner
                ? 'Logging in ...'
                : 'Login'
            }
          </Button>
        </div>
      );
    }
    if (loginType === 'gsuite') {
      return (
        <p style={style.errorMessage}>
          {errorMessage}
        </p>
      );
    }

    return (
      <p style={style.errorMessage}>
        {errorMessage}
      </p>
    );
  };

  return (
    <LoginView>
      <div className="/login">
        <IconButton
          style={{ padding: 0, marginBottom: 48 }}
          onClick={() => {
            history.push('/login');
          }}
          color="primary"
          aria-label="back"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5">
          Organization Login
        </Typography>

        {renderLogin()}
      </div>
    </LoginView>
  );
};

OrgLogin.defaultProps = {
  type: '',
};

export default OrgLogin;
