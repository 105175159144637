export enum UserLevel {
  NORMAL = 'NORMAL',
  POWER_USER = 'POWER_USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum ProfileImageType {
  GOOGLE = 'GOOGLE',
  PREDEFINED = 'PREDEFINED',
}

export interface User {
  firstName: string,
  lastName: string,
  email: string | null,
  userId: string,
  userLevel: UserLevel,
  googleAccessToken: string,
  googleRefreshToken: string,
  azureAccessToken: string,
  azureRefreshToken: string,
  bitwardsUsername: string | null,
  bitwardsPassword: string | null,
  available: boolean,
  enabled: boolean | null,
  createdDate?: string,
  userStatus: string | null,
  orgId: string,
  createdAt: string | null,
  updatedAt: string | null,
  lastActiveTime: string | null,
}

export interface UpdateUserInput {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  userId: string,
  userLevel?: string | null,
  googleAccessToken?: string | null,
  googleRefreshToken?: string | null,
  azureAccessToken?: string | null,
  azureRefreshToken?: string | null,
  bitwardsUsername?: string | null,
  bitwardsPassword?: string | null,
  available?: boolean | null,
  enabled?: boolean | null,
  createdDate?: string | null,
  userStatus?: string | null,
  orgId?: string | null,
  lastActiveTime?: string | null,
}

export interface ReadUserQuery {
  readUser: User | null
}

export interface DeleteUserInput {
  userId: string,
}

export interface UpdateUserMutation {
  updateUser: User | null,
}

export interface DeleteUserMutation {
  deleteUser: User | null,
}

export interface ReadAllUsersByOrgQuery {
  readAllUsersByOrg: {
    items: User[],
    nextToken: string | null
  }
}

interface SharedUserData {
  userId: string,
  email: string,
  firstName: string,
  lastName: string,
  available: boolean,
  userStatus: string | null,
  profileImageType: ProfileImageType | null,
  profileImageUrl: string | null,
  createdAt: string | null,
  updatedAt: string | null,
}

export type UpdateSharedUserDataInput = {
  email: string,
  firstName: string,
  lastName: string,
  userId: string;
};

export interface ReadSharedUserDataQuery {
  readSharedUserData: SharedUserData | null,
}

export interface UpdateSharedUserDataMutation {
  updateSharedUserData: SharedUserData,
}
