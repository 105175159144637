import React from 'react';

import Typography from '@material-ui/core/Typography';

type StyleProps = {
  container: React.CSSProperties,
  apikey: React.CSSProperties,
};

const style: StyleProps = {
  container: {
    margin: '16px',
  },
  apikey: {
    maxWidth: '80%',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
};

type ApiKeyProps = {
  accessLevel: string,
  apiKey: string,
};

const ApiKey: React.FC<ApiKeyProps> = ({ accessLevel, apiKey }: ApiKeyProps) => (
  <div style={style.container}>
    <strong>{accessLevel}</strong>
    <br />
    <Typography
      variant="caption"
      style={style.apikey}
    >
      {apiKey || 'Not Available'}
    </Typography>
  </div>
);

export default ApiKey;
