import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import Entry from './components/Entry';
import './styles/index.scss';

const App: React.FC = () => (
  <div className="section">
    <CssBaseline />
    <Entry />
  </div>
);

export default App;
