import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  MouseEvent,
} from 'react';
import './styles.scss';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { IconButton, TableSortLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination/TablePagination';
import JSONLint from 'json-lint';

import history from '../history';
import {
  MenuElement,
  DialogElement,
  SnackbarMessageElement,
} from '../../configs/types/Common';
import { UpdateOrgInput } from '../../configs/types/Org';
import { User } from '../../configs/types/User';
import {
  EmailPattern,
  DeleteEmailPatternInput,
} from '../../configs/types/EmailPattern';
import MenuSelect from './MenuSelect';
import ConfirmActionDialog from '../Dialog/ConfirmActionDialog';
import { deleteEmailPattern } from '../../configs/graphql/emailPattern';
import { readOrgData } from '../../configs/graphql/org';

import SnackbarMessage from '../Dialog/SnackbarMessage';

export type TableTitleIds = keyof User | keyof EmailPattern | keyof UpdateOrgInput | 'edit';
export type TableTitles = {
  title: string,
  id: TableTitleIds,
  numeric: boolean
};
type DataContent = User | EmailPattern | UpdateOrgInput;
type Data = DataContent[];
type Order = 'asc' | 'desc';

interface TableComponentProps {
  type: 'approvedEmails' | 'registeredUsers' | 'organizations';
  tableTitles: TableTitles[];
  columns: string[];
  data: Data
  updateInvitees?: () => void;
}

const TableComponent = forwardRef((props: TableComponentProps, ref) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dialogType, setDialogType] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState<EmailPattern | null>();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<TableTitleIds>('email');

  const invitedUserMenuRef = useRef<MenuElement>();
  const dialogRef = useRef<DialogElement>();

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const snackbarRef = useRef<SnackbarMessageElement>();

  useImperativeHandle(
    ref,
    () => ({
      handleSetPage() {
        setPage(0);
      },
    }),
  );

  const handleEditClick = async (
    event: MouseEvent<HTMLButtonElement>,
    item: DataContent,
  ) => {
    switch (props.type) {
      case 'registeredUsers': {
        const { userId } = (item as User);
        history.push(`/user-management?userId=${userId}`, { user: item });
        break;
      }
      case 'approvedEmails': {
        setSelectedRow(item as EmailPattern);
        invitedUserMenuRef?.current?.handleOpen(event);
        break;
      }
      case 'organizations': {
        const { orgId } = (item as UpdateOrgInput);
        try {
          const res = await readOrgData(orgId as string);
          if (res.data?.readOrg) {
            const data = res.data?.readOrg;
            const feedbackConfigLint = JSONLint(data.feedbackConfig);
            if (!feedbackConfigLint.error) {
              data.feedbackConfig = JSON.parse(data.feedbackConfig);
            }

            const liveConfigLint = JSONLint(data.liveConfig);
            if (!liveConfigLint.error) {
              data.liveConfig = JSON.parse(data.liveConfig);
            }

            const smartmapSDKConfigLint = JSONLint(data.smartmapSDKConfig);
            if (!smartmapSDKConfigLint.error) {
              data.smartmapSDKConfig = JSON.parse(data.smartmapSDKConfig);
            }

            const azureClientCredentialsLint = JSONLint(data.azureClientCredentials);
            if (!azureClientCredentialsLint.error) {
              data.azureClientCredentials = JSON.parse(data.azureClientCredentials);
            }

            const extrasLint = JSONLint(data.extras);
            if (!extrasLint.error) {
              data.extras = JSON.parse(data.extras);
            }
            const mergatorLint = JSONLint(data.externalOrgMergeConfigs);
            if (!mergatorLint.error) {
              data.externalOrgMergeConfigs = JSON.parse(data.externalOrgMergeConfigs);
            }
            history.push(`/organization-management?orgId=${orgId}`, { organization: data });
          } else if (res.errors) {
            const errorMessages: string[] = [];
            res.errors?.forEach((error: Error) => {
              errorMessages.push(error.message);
            });
            setSnackbarMessage(`Could not open organization. ${errorMessages}`);
            snackbarRef?.current?.handleOpen();
          }
          break;
        } catch (error) {
          console.log('error', error);
        }
        break;
      }
      default:
        break;
    }
  };

  const onInvitedUserMenuItemSelect = (): void => {
    setDialogType('deleteApprovedEmailDialog');
    dialogRef?.current?.handleOpen();
  };

  const handleDeleteInvitee = async () => {
    if (selectedRow && selectedRow.addressPattern) {
      const emailAddressLookupInput: DeleteEmailPatternInput = {
        addressPattern: selectedRow.addressPattern,
      };
      const { data } = await deleteEmailPattern(emailAddressLookupInput);
      if (data?.deleteEmailPattern) {
        if (props.updateInvitees) {
          props.updateInvitees();
        }
      } else {
        setSnackbarMessage('Could not remove the invitee');
        snackbarRef?.current?.handleOpen();
      }
    }
  };

  const onDialogConfirm = (type: string) => {
    switch (type) {
      case 'deleteApprovedEmailDialog':
        handleDeleteInvitee();
        break;

      default:
        break;
    }
    dialogRef?.current?.handleClose();
    setSelectedRow(null);
  };

  const onDialogCancel = () => {
    dialogRef?.current?.handleClose();
    setSelectedRow(null);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const invitedUserMenuItems = [
    {
      text: 'Remove',
      value: 'REMOVE',
    },
  ];
  const invitedUserMenu = ' invitedUserMenu';

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: TableTitleIds,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator<T>(a: T, b: T, currOrderBy: keyof T) {
    if (b[currOrderBy] < a[currOrderBy]) {
      return -1;
    }
    if (b[currOrderBy] > a[currOrderBy]) {
      return 1;
    }
    return 0;
  }

  const visibleRows: Data = React.useMemo(() => {
    function getComparator<Key extends keyof any>(
      currOrder: Order,
      currOrderBy: Key,
    ): (
        a: { [key in Key]: TableTitleIds },
        b: { [key in Key]: TableTitleIds },
      ) => number {
      return currOrder === 'desc'
        ? (a, b) => descendingComparator(a, b, currOrderBy)
        : (a, b) => -descendingComparator(a, b, currOrderBy);
    }

    function stableSort<T>(array: Data, comparator: (a: T, b: T) => number) {
      // eslint-disable-next-line max-len
      const stabilizedThis = array.map((el, index) => [el, index] as [unknown, number] as [T, number]);
      stabilizedThis.sort((a, b) => {
        const currOrder = comparator(a[0], b[0]);
        if (currOrder !== 0) {
          return currOrder;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    return stableSort(props.data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [order, orderBy, page, props.data, rowsPerPage]);

  return (
    <div>
      <TableContainer>
        <Table
          className="table"
          aria-label="simple table"
          size="small"
        >
          <TableHead>
            <TableRow className="table-title">
              {
                /**
                 * set the table titles and align first to the left
                 * */
                props.tableTitles.map((headCell: TableTitles, index: number) => {
                  const { title, id } = headCell;
                  const alignment = index === 0 ? 'left' : 'right';
                  return (
                    <TableCell
                      key={title}
                      align={alignment}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, id)}
                      >
                        {title}
                      </TableSortLabel>

                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
            visibleRows
              .map((item: DataContent, propIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={propIndex}>
                  {props.columns.map((column: string, columnIndex: number) => {
                    let fieldData = (item as unknown as { [key: string]: string })[column];
                    const alignment = columnIndex === 0 ? 'left' : 'right';

                    if (fieldData) {
                      if (
                        column === 'createdAt'
                          || column === 'updatedAt'
                      ) {
                        fieldData = new Date(fieldData).toLocaleDateString();
                      } else if (column === 'lastActiveTime') {
                        fieldData = new Date(fieldData).toLocaleString();
                      }
                    }

                    return (
                      <TableCell
                        key={column}
                        align={alignment}
                      >
                        {fieldData}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={{ paddingRight: 0 }}
                    align="right"
                  >
                    <IconButton
                      aria-label="edit"
                      onClick={(event) => handleEditClick(event, item)}
                    >
                      <EditIcon />
                    </IconButton>

                    <MenuSelect
                      type={invitedUserMenu}
                      ref={invitedUserMenuRef}
                      items={invitedUserMenuItems}
                      onMenuItemSelect={onInvitedUserMenuItemSelect}
                    />

                  </TableCell>
                </TableRow>
              ))
}
            {emptyRows > 0 && (
              <TableRow style={{ height: 4 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, props.data.length]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ConfirmActionDialog
        type={dialogType}
        ref={dialogRef}
        onConfirm={onDialogConfirm}
        onCancel={onDialogCancel}
      />

      <SnackbarMessage
        message={snackbarMessage}
        ref={snackbarRef}
      />
    </div>
  );
});

TableComponent.displayName = 'TableComponent';

TableComponent.defaultProps = {
  updateInvitees: () => {},
};

export default TableComponent;
