import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { cognito } from './Cognito';
import LoginView from './LoginView';

import {
  saveAPIKeys,
  useAuth,
} from './utils';

const style = {
  loginButton: {
    marginTop: 24,
    marginBottom: 24,
  },
  orgLoginButton: {
    marginTop: 16,
    width: '100%',
  },
  errorMessage: {
    color: 'red',
  },
};

const Login: React.FC = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const [useDashboardLogin, setUseDashboardLogin] = useState(false);

  const history = useHistory();
  const { setAuth } = useAuth();

  // eslint-disable-next-line consistent-return
  const fetchUserData = useCallback(async () => {
    const userAttributes = await cognito.getUserAttributes();

    saveAPIKeys({
      editorKeyReadWriteMerge: userAttributes['custom:editor_api_key'],
      editorKeyRead: userAttributes['custom:editor_r'],
      importKeyReadWriteMerge: userAttributes['custom:import_api_key'],
      publishedKeyReadWriteMerge: userAttributes['custom:published_api_key'],
      publishedKeyRead: userAttributes['custom:published_r'],
      liveKeyReadWriteMerge: userAttributes['custom:live_api_key'],
      liveKeyRead: userAttributes['custom:live_r'],
      metaLiveKeyReadWriteMerge: userAttributes['custom:metalive_api_key'],
      metaLiveKeyRead: userAttributes['custom:metalive_r'],
    });
    localStorage.setItem('loginType', 'dashboard');
    setAuth(true);
    if (process.env.REACT_APP_ENV === 'development') {
      return history.push('/profile');
    }

    window.location.href = '/buildings';
  }, [history, setAuth]);

  useEffect(() => {
    const checkAuthenticated = async () => {
      try {
        setShowLoginSpinner(true);

        cognito.switchToDashboardAppPool();
        const isAuthenticated = await cognito.isAuthenticated();
        if (isAuthenticated) {
          await fetchUserData();
          setShowLoginSpinner(false);
        } else {
          localStorage.clear();
          setShowLoginSpinner(false);
        }
      } catch (err: any) {
        setErrorMessage(err.message);
        localStorage.clear();
        setShowLoginSpinner(false);
        console.error(err);
      }
    };

    checkAuthenticated();
  }, [fetchUserData]);

  const loginWithDashboard = async () => {
    setErrorMessage('');

    if (!email) {
      setErrorMessage('Email is required.'); return;
    }
    if (!password) {
      setErrorMessage('Password is required.');
      return;
    }

    setShowLoginSpinner(true);

    try {
      await cognito.signIn(email, password);
      await fetchUserData();
    } catch (err: any) {
      setErrorMessage(err.message);
      console.error(err);
      setShowLoginSpinner(false);
    }
  };

  const loginWithMicrosoft = async () => {
    history.push('/company-login', { type: 'o365exchange' });
  };

  const loginWithGoogle = async () => {
    history.push('/company-login', { type: 'gsuite' });
  };

  const loginWithSteerpath = async () => {
    history.push('/company-login', { type: 'steerpath' });
  };

  const handleOrgLogin = (type: string) => {
    switch (type) {
      case 'gsuite':
        loginWithGoogle();
        break;
      case 'o365exchange':
        loginWithMicrosoft();
        break;
      case 'steerpath':
        loginWithSteerpath();
        break;
      case 'dashboard':
        cognito.switchToDashboardAppPool();
        setUseDashboardLogin(true);
        break;
      default:
        break;
    }
  };

  const startIcon = (name: string) => (
    <Icon style={{ width: 20, height: 20, display: 'flex' }}>
      <img
        alt="gsuite"
        src={`./icons/${name}.svg`}
      />
    </Icon>
  );

  const loadingCircle = (
    <div style={{
      marginLeft: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {showLoginSpinner && <CircularProgress size={32} />}
    </div>
  );

  return (
    <LoginView>
      <div className="/login">
        {useDashboardLogin ? (
          <IconButton
            style={{ padding: 0, marginBottom: 48 }}
            onClick={() => {
              setUseDashboardLogin(false);
            }}
            color="primary"
            aria-label="back"
          >
            <ArrowBack />
          </IconButton>
        ) : (<div />)}
        <Typography variant="h5">
          Login
        </Typography>

        {useDashboardLogin
          ? (
            <div>

              <TextField
                autoFocus
                fullWidth
                id="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <Typography
                variant="body1"
                style={style.errorMessage}
              >
                {errorMessage}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  style={style.loginButton}
                  variant="contained"
                  color="primary"
                  onClick={loginWithDashboard}
                  disabled={showLoginSpinner}
                >
                  {showLoginSpinner ? 'Logging in ...' : 'Login'}
                </Button>
                {loadingCircle}
              </div>
              <Typography>
                {'Don\'t have an account yet? '}
                <Link to="/signup">
                  Register
                </Link>
              </Typography>

              <Link to="/forgot-password">
                Forgot password?
              </Link>
            </div>
          ) : (
            <div style={{ marginTop: 24 }}>
              <Typography variant="h6">
                Standard Login
              </Typography>
              <Button
                style={style.orgLoginButton}
                variant="contained"
                color="primary"
                startIcon={startIcon('mail')}
                disabled={showLoginSpinner}
                onClick={() => handleOrgLogin('dashboard')}
              >
                LOGIN WITH EMAIL AND PASSWORD
              </Button>

              <Typography
                variant="h6"
                style={{ marginTop: 24 }}
              >
                Organization Login
              </Typography>
              <Button
                style={style.orgLoginButton}
                variant="contained"
                color="primary"
                startIcon={startIcon('steerpath')}
                disabled={showLoginSpinner}
                onClick={() => handleOrgLogin('steerpath')}
              >
                LOGIN WITH EMAIL AND PASSWORD
              </Button>

              <Button
                style={style.orgLoginButton}
                variant="contained"
                color="primary"
                startIcon={startIcon('o365exchange')}
                disabled={showLoginSpinner}
                onClick={() => handleOrgLogin('o365exchange')}
              >
                LOGIN WITH MICROSOFT
              </Button>

              <Button
                style={style.orgLoginButton}
                variant="contained"
                color="primary"
                startIcon={startIcon('gsuite')}
                disabled={showLoginSpinner}
                onClick={() => handleOrgLogin('gsuite')}
              >
                LOGIN WITH GOOGLE
              </Button>

            </div>
          )}
      </div>
    </LoginView>
  );
};

export default Login;
