import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';

interface SnackbarMessageProps {
  message: string,
  snackbarButton?: string,
  onButtonClick?: () => void,
}
// const MenuSelect = forwardRef((props: MenuSelectProps, ref) => {

const SnackbarMessage = forwardRef(({
  message,
  snackbarButton,
  onButtonClick,
}: SnackbarMessageProps, ref) => {
  const [open, setOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {
        setOpen(true);
      },
      handleClose() {
        setOpen(false);
      },
    }),
  );

  const handleClose = (_event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    if (onButtonClick) onButtonClick();
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={(
          <>
            {snackbarButton
              ? (
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleClick}
                >
                  {snackbarButton}
                </Button>
              )
              : <div />}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </div>
  );
});

SnackbarMessage.displayName = 'SnackbarMessage';

SnackbarMessage.defaultProps = {
  onButtonClick: () => {},
  snackbarButton: '',
};

export default SnackbarMessage;
