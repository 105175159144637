import {
  FC,
  ReactNode,
} from 'react';

import sortBy from 'lodash.sortby';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const style = {
  buildingSelector: {
    color: '#fff',
    height: '48px',
  },
};

interface Building {
  title: string,
  buildingRef: string,
}

type BuildingSelectorProps = {
  activeBuilding: string,
  buildings: Building[],
  handleBuildingSelectorChange: (value: string) => void,
};

const BuildingSelector: FC<BuildingSelectorProps> = ({
  activeBuilding,
  buildings,
  handleBuildingSelectorChange,
}: BuildingSelectorProps) => {
  // const onBuildingSelectorChange = (event: ChangeEvent<{ value: unknown }>) => {
  //   handleBuildingSelectorChange(event.target.value as string);
  // };

  let selectMenuItems: ReactNode = <div />;
  if (buildings && buildings.length) {
    selectMenuItems = sortBy(buildings, 'title').map(
      (building) => (
        <MenuItem
          onClick={() => handleBuildingSelectorChange(building.buildingRef)}
          className="steerpath-toolbar-select-item"
          key={building.buildingRef}
          value={building.buildingRef}
        >
          {building.title}
        </MenuItem>
      ),
    );
  }

  const BuildingSelectMenu = () => buildings && (
    <FormControl>
      <Select
        className="steerpath-toolbar-select-menu"
        value={activeBuilding}
        inputProps={{
          name: 'selectedItem',
          id: 'selectedItem-select',
        }}
        autoWidth
        style={{
          fontWeight: 'bold',
          fontSize: '24px',
          color: 'white',
        }}
      >
        {selectMenuItems}
      </Select>
    </FormControl>
  );

  return (
    <div style={style.buildingSelector}>
      <BuildingSelectMenu />
    </div>
  );
};

export default BuildingSelector;
