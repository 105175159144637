import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from 'react';

interface ConfirmActionDialogProps {
  type: string;
  onConfirm: (type: string) => void;
  onCancel: (type: string) => void;
}
// const MenuSelect = forwardRef((props: MenuSelectProps, ref) => {

const ConfirmActionDialog = forwardRef((props: ConfirmActionDialogProps, ref) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [cancelButtonText, setCancelButtonText] = useState('');

  const setDialogTexts = useCallback(() => {
    switch (props.type) {
      case 'saveDialog':
        setTitle('Save changes?');
        setDescription('Do you want to save your changes to this user?');
        setConfirmButtonText('Save');
        setCancelButtonText('Cancel');
        break;
      case 'deleteDialog':
        setTitle('Delete user?');
        setDescription('Do you want to delete user?');
        setConfirmButtonText('Delete');
        setCancelButtonText('Cancel');
        break;
      case 'deleteApprovedEmailDialog':
        setTitle('Delete invitee?');
        setDescription('Do you want to delete invitee? Please note that this action will not delete any registered users');
        setConfirmButtonText('Delete');
        setCancelButtonText('Cancel');
        break;
      case 'resetProfileImage':
        setTitle('Reset profile image?');
        setDescription('Do you want to reset profile image?');
        setConfirmButtonText('Reset');
        setCancelButtonText('Cancel');
        break;
      case 'saveDialogOrganization':
        setTitle('Save Organization');
        setDescription('Do you want to save your changes to this organization');
        setConfirmButtonText('Save');
        setCancelButtonText('Cancel');
        break;
      case 'deleteDialogOrganization':
        setTitle('Delete Organization?');
        setDescription('Do you want to delete Organization?');
        setConfirmButtonText('Delete');
        setCancelButtonText('Cancel');
        break;
      case 'failed_to_invite_new_admin_on_organization_create':
        setTitle('Failed to invite admin to the system.');
        setDescription('Organization created successfully but failed to invite the admin. Please try again after a while');
        setConfirmButtonText('Back to main screen');
        setCancelButtonText('Cancel');
        break;
      case 'setDefault_feedbackConfig':
      case 'setDefault_liveConfig':
      case 'setDefault_smartmapSDKConfig':
      case 'setDefault_azureClientCredentials':
      case 'setDefault_externalOrgMergeConfigs':
      case 'setDefault_extras':
        setTitle('Set the default configuration?');
        setDescription('Do you want to set the default value for this field. Do note that it will override existing value.');
        setConfirmButtonText('Set Default');
        setCancelButtonText('Cancel');
        break;
      default:
        break;
    }
  }, [props.type]);

  useEffect(() => {
    setDialogTexts();
  }, [setDialogTexts]);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {
        setOpen(true);
      },
      handleClose() {
        setOpen(false);
      },
    }),
  );

  const handleConfirm = () => {
    props.onConfirm(props.type);
    // setOpen(false);
  };

  const handleCancel = () => {
    props.onCancel(props.type);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

ConfirmActionDialog.displayName = 'ConfirmActionDialog';

export default ConfirmActionDialog;
