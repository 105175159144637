import {
  FC,
} from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';

// public
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import defaultSmartmapSDKConfig from '@steerpath/common-resources/smart-sdk-default-config/steerpath_default_config.json';

import MasterHeader from './MasterHeader';

import NotFound from './NotFound';
import {
  ForgotPassword,
  Login,
  OrgLogin,
  Logout,
  SignUp,
} from './CognitoLogin';
import Migrate from './Migrate';
import { cognito } from './CognitoLogin/Cognito';
import { useAuth } from './CognitoLogin/utils';
import UserManagement from './UserManagement/UserManagement';
import UserEdit from './UserManagement/UserEdit';
import OrganizationEdit from './OrganizationManagement/OrganizationEdit';
import OrganizationManagement from './OrganizationManagement/OrganizationManagement';
import history from './history';

// authentication required
import Profile from './Profile';
import { NavigationParams } from '../configs/types/Common';
import {
  CalendarProvider,
  UpdateOrgInput,
} from '../configs/types/Org';
import { User } from '../configs/types/User';
import '../styles/index.scss';

import MapExport from './MapExport/MapExport';
import defaultLiveConfig from './OrganizationManagement/live_default_config.json';
import defaultAzureClientCredentialsConfig from './OrganizationManagement/azureClientCredentials_config.json';
import { defaultFeedbackConfig } from './OrganizationManagement/defaultFeedbackConfig';

if (localStorage.getItem('fromOfficeApp') === 'true') {
  cognito.switchToOfficeAppPool();
} else {
  cognito.switchToDashboardAppPool();
}

interface CustomRouteProps extends Omit<RouteProps, 'component'> {
  component: any,
}

const PrivateRoute: FC<CustomRouteProps> = ({
  component: Component,
  ...rest
}: CustomRouteProps) => {
  const { auth } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (auth ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ))}
    />
  );
};

const PublicRoute: FC<CustomRouteProps> = ({
  component: Component,
  ...rest
}: CustomRouteProps) => {
  const { auth } = useAuth();
  const privatePathName = process.env.REACT_APP_ENV === 'development'
    ? '/profile' : '/buildings';

  if (auth) {
    window.location.href = privatePathName;
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

const UserManagementComponent = (params: NavigationParams) => {
  if (localStorage.getItem('fromOfficeApp') === 'true') {
    if (
      params.location.pathname === '/user-management'
      && params.location.search.includes('?userId=')
      && params.location.state
    ) {
      const { user } = params.location.state;
      return (
        <UserEdit user={(user as User)} />
      );
    }
    return (
      <UserManagement />
    );
  }
  return (
    <NotFound />
  );
};

const OrgLoginComponent = (params: NavigationParams) => {
  if (params.location.pathname === '/company-login') {
    if (params.location.state) {
      const { type } = params.location.state;
      return (<OrgLogin type={type} />);
    }
    return (<OrgLogin />);
  }
  return (
    <NotFound />
  );
};

const SignUpComponent = (params:NavigationParams) => {
  if (params.location.state) {
    const { type } = params.location.state;
    return (<SignUp type={type} />);
  }
  return (<SignUp />);
};

const OrganizationManagementComponent = (params: NavigationParams) => {
  if (
    params.location.pathname === '/organization-management'
    && params.location.search.includes('?orgId=')
    && params.location.state
  ) {
    const { organization } = params.location.state;
    return (
      <OrganizationEdit
        isNewOrganization={false}
        organization={(organization as unknown as UpdateOrgInput)}
      />
    );
  }
  return (<OrganizationManagement />);
};

const AddNewOrganizationComponent = () => {
  const defaultOrganization: UpdateOrgInput = {
    orgName: '',
    calendarProvider: CalendarProvider.STEERPATH,
    calendarBackendUrl: '',
    idpFederationProvider: 'COGNITO',
    idpFederationTenantName: '',

    azureToken: '',
    googleToken: '',

    feedbackConfig: defaultFeedbackConfig,
    feedbackEmail: 'support@steerpath.com',
    typeformURL: '',

    liveConfig: defaultLiveConfig,
    liveSyncEnabled: false,
    hasBitwards: false,
    hasBitwardsOAuth: false,
    smartmapSDKConfig: defaultSmartmapSDKConfig,
    azureClientCredentials: defaultAzureClientCredentialsConfig,
    extras: {},
    externalOrgMergeConfigs: [],
    ghostBustingEnabled: false,
    carParkingEnabled: false,
    sensorSpeedUp: false,
    mixpanelToken: '',
  };
  return (
    <OrganizationEdit
      organization={defaultOrganization}
      isNewOrganization
    />
  );
};

const Entry: FC = () => (
  <div className="section">

    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#34a2c6',
            contrastText: '#fff',
          },
        },
      })}
    >
      <Router history={history}>
        <div className="section">
          <MasterHeader />
          <Switch>
            <PrivateRoute
              exact
              path="/profile"
              component={Profile}
            />
            <PrivateRoute
              path="/user-management"
              component={UserManagementComponent}
            />
            <PrivateRoute
              path="/add-new-organization"
              component={AddNewOrganizationComponent}
            />
            <PrivateRoute
              path="/organization-management"
              component={OrganizationManagementComponent}
            />
            <PrivateRoute
              path="/map-export"
              component={MapExport}
            />

            <PublicRoute
              path="/login"
              component={Login}
            />
            <PublicRoute
              path="/company-login"
              component={OrgLoginComponent}
            />
            <PrivateRoute
              path="/logout"
              component={Logout}
            />
            <PublicRoute
              path="/signup"
              component={SignUpComponent}
            />
            <PublicRoute
              path="/forgot-password"
              component={ForgotPassword}
            />
            <PublicRoute
              path="/migrate"
              component={Migrate}
            />
            <PublicRoute
              path="/"
              component={Login}
            />

            <Route component={NotFound} />
          </Switch>
        </div>

      </Router>
    </ThemeProvider>
  </div>
);

export default Entry;
