export enum CalendarProvider {
  GOOGLE = 'GOOGLE',
  OFFICE365 = 'OFFICE365',
  STEERPATH = 'STEERPATH',
  TIMEWORKS = 'TIMEWORKS',
}

export enum SharedUserDataTableVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export type IdentityProvider = 'AZURE' | 'COGNITO' | 'GOOGLE' | 'SAML';

export interface Org {
  orgId: string,
  orgName: string,
  feedbackEmail: string | null,
  smartmapAPIKey: string | null,
  liveKeyRead: string | null,
  liveKeyReadWriteMerge: string | null,
  editorKeyRead: string | null,
  editorKeyReadWriteMerge: string | null,
  metaLiveKeyRead: string | null,
  metaLiveKeyReadWriteMerge: string | null,
  publishedKeyRead: string | null,
  publishedKeyReadWriteMerge: string | null,
  importKeyRead: string | null,
  importKeyReadWriteMerge: string | null,
  smartmapSDKConfig: string,
  hasBitwards: boolean,
  typeformURL: string,
  liveConfig: string,
  calendarProvider: CalendarProvider,
  calendarBackendUrl: string,
  googleToken: string,
  azureToken: string,
  feedbackConfig: string,
  liveSyncEnabled: boolean | null,
  createdAt: string | null,
  updatedAt: string | null,
  sharedUserDataTableVersion?: SharedUserDataTableVersion,
  azureClientCredentials: string,
  hasBitwardsOAuth: boolean,
  ghostBustingEnabled: boolean,
  carParkingEnabled: boolean,
  extras: string,
  externalOrgMergeConfigs: string
  mixpanelToken: string;
  sensorSpeedUp: boolean;
  idpFederationProvider?: IdentityProvider;
  idpFederationTenantName?: string;
}

export interface UpdateOrgInput {
  orgId?: string,
  orgName?: string | null,
  feedbackEmail?: string | null,
  smartmapAPIKey?: string | null,
  liveKeyRead?: string | null,
  liveKeyReadWriteMerge?: string | null,
  editorKeyRead?: string | null,
  editorKeyReadWriteMerge?: string | null,
  metaLiveKeyRead?: string | null,
  metaLiveKeyReadWriteMerge?: string | null,
  publishedKeyRead?: string | null,
  publishedKeyReadWriteMerge?: string | null,
  importKeyRead?: string | null,
  importKeyReadWriteMerge?: string | null,
  smartmapSDKConfig?: string | Record<string, unknown>,
  hasBitwards?: boolean,
  typeformURL?: string | null,
  liveConfig?: string | Record<string, unknown>,
  calendarProvider: CalendarProvider,
  calendarBackendUrl?: string | null,
  feedbackConfig?: string | Record<string, unknown>,
  liveSyncEnabled?: boolean | null,
  azureToken?: string | null,
  googleToken?: string | null,
  // sharedUserDataTableVersion?: SharedUserDataTableVersion,
  azureClientCredentials?: string | Record<string, unknown>,
  hasBitwardsOAuth?: boolean,
  ghostBustingEnabled?: boolean,
  carParkingEnabled?: boolean,
  extras?: string | Record<string, unknown>,
  externalOrgMergeConfigs?: string | Record<string, unknown>[],
  mixpanelToken?: string | null;
  sensorSpeedUp?: boolean;
  idpFederationProvider?: IdentityProvider;
  idpFederationTenantName?: string;
}

export interface UpdateOrgMutation {
  updateOrg: Org | null,
}

export interface ReadOrgQuery {
  readOrg: Org | null,
}

export interface CreateOrgMutation {
  createOrg: Org | null,
}

export interface DeleteOrgMutation {
  deleteOrg: Org | null,
}

export interface ReadAllOrgsQuery {
  readAllOrgs: Org[] | null,
}

export interface UpsertOrgMutation {
  updateOrg?: Org | null,
  createOrg?: Org | null,
}
